import PropTypes from 'prop-types';
import React, { createContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ApiProvider } from './Api';
import { AuthProvider } from './Auth';
import { PerguntasProvider } from './listagem/Perguntas';
import { ErrorHandlerProvider } from './ErrorHandler';
import { FuncoesProvider } from './FuncoesGerais';
import TemaProvider from './Tema';
import { UsuariosProvider } from './listagem/Usuarios';
import { DiretoresProvider } from './listagem/Diretores';
import { ClientesProvider } from './listagem/Clientes';
import { RoteirosProvider } from './listagem/Roteiros';
import { ParoquiasProvider } from './listagem/Paroquias';
import { AtendimentosProvider } from './listagem/Atendimentos';
import { SessaoProvider } from './Sessao';

export const HooksContext = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function HooksProvider({ children }) {
  return (
    <HooksContext.Provider value={null}>
      <TemaProvider>
        <BrowserRouter>
          <ErrorHandlerProvider>
            <AuthProvider>
              <ApiProvider>
                <SessaoProvider>
                  <UsuariosProvider>
                    <PerguntasProvider>
                      <DiretoresProvider>
                        <ClientesProvider>
                          <RoteirosProvider>
                            <ParoquiasProvider>
                              <AtendimentosProvider>
                                <FuncoesProvider>{children}</FuncoesProvider>
                              </AtendimentosProvider>
                            </ParoquiasProvider>
                          </RoteirosProvider>
                        </ClientesProvider>
                      </DiretoresProvider>
                    </PerguntasProvider>
                  </UsuariosProvider>
                </SessaoProvider>
              </ApiProvider>
            </AuthProvider>
          </ErrorHandlerProvider>
        </BrowserRouter>
        <ToastContainer />
      </TemaProvider>
    </HooksContext.Provider>
  );
}

HooksProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
