import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import OnibusImg from '../../media/onibus.png';

import Draggable from 'react-draggable';

/**
 * @param {*} props
 * @return {Node}
 */
function PaperComponent(props) {
  return (
    <Draggable
      handle='#draggable-dialog-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

/**
 * @return {Node}
 */
function VisualizarOnibus() {
  const [open, setOpen] = useState(false);

  /**
   */
  function handleOpen() {
    setOpen(true);
  }

  /**
   */
  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Button style={{ float: 'right' }} onClick={handleOpen}>
        Visualizar onibus
      </Button>
      <Dialog
        open={open}
        PaperComponent={PaperComponent}
        fullWidth
        maxWidth={'md'}
      >
        <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
          Visualização dos assentos
        </DialogTitle>
        <DialogContent>
          <a href={OnibusImg} target='_blank' rel='noreferrer'>
            <img src={OnibusImg} width={'100%'} />
          </a>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

VisualizarOnibus.propTypes = {
  open: PropTypes.bool,
};

export default VisualizarOnibus;
