import {
  Button,
  Paper,
  Stack,
  TableContainer,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFuncoes } from '../../hooks/FuncoesGerais';
import { useDiretores } from '../../hooks/listagem/Diretores';
import { useParoquias } from '../../hooks/listagem/Paroquias';
import { formatarDataHora } from '../../uteis/Funcoes';
import { mobile } from '../../uteis/Parametros';
import Acoes from './Acoes';
import { AlterarProvider } from './Acoes/Alterar';
import { ExcluirClienteProvider } from './Acoes/Excluir';
import { NovaParoquiaProvider, useNovaParoquia } from './Acoes/Novo';
import { VisualizarParoquiaProvider } from './Acoes/Visualizar';

const titulo = 'Paróquias';
const botaoCadastroMensagem = 'Nova Paróquia';

/**
 * @return {object}
 */
function ColunasHead() {
  return (
    <TableRow>
      <TableCell align='center'>Id</TableCell>
      <TableCell align='center'>Nome</TableCell>
      <TableCell align='center'>Fone</TableCell>
      <TableCell align='center'>Cidade</TableCell>
      <TableCell align='center'>Diretor</TableCell>
      <TableCell align='center'>Última alteração</TableCell>
      <TableCell align='center'>Data de cadastro</TableCell>
      <TableCell align='center'></TableCell>
    </TableRow>
  );
}

/**
 * @return {object}
 */
function ListaSkeleton() {
  return Array(5)
    .fill()
    .map((item, index) => (
      <TableRow key={`skeleton-cliente-item-${index}`}>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'2ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'7ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton
            variant='rounded'
            width={'102.36px'}
            height={'36.5px'}
            style={{ float: 'inline-end' }}
          />
        </TableCell>
      </TableRow>
    ));
}

/**
 * @param {array} listagem
 * @param {func} tentarNovamente
 * @return {object}
 */
function Lista({ listagem, tentarNovamente }) {
  const [setNovoAberto] = useNovaParoquia();
  const [diretores] = useDiretores();

  /**
   */
  function handleNovoItem() {
    setNovoAberto(true);
  }

  return (
    <React.Fragment>
      <Stack
        p={2}
        justifyContent={mobile ? 'unset' : 'space-between'}
        alignItems={mobile ? 'unset' : 'center'}
        direction={mobile ? 'column' : 'row'}
      >
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          {titulo}
        </Typography>
        <Button variant='contained' onClick={handleNovoItem}>
          {botaoCadastroMensagem}
        </Button>
      </Stack>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <TableContainer
          sx={{ border: '1px solid #0000001a', borderRadius: '3px' }}
        >
          <Table size='small' aria-label='caption table'>
            <TableHead>
              <ColunasHead />
            </TableHead>
            <TableBody>
              {listagem && diretores ? (
                listagem.map((cliente, index) => (
                  <TableRow
                    key={`${titulo.toLocaleLowerCase()}-item-map-${index}`}
                  >
                    <TableCell align='center'>{cliente.id}</TableCell>
                    <TableCell align='center'>{cliente.nome}</TableCell>
                    <TableCell align='center'>{cliente.fone}</TableCell>
                    <TableCell align='center'>
                      {cliente.cidade ? cliente.cidade : 'Não informado'}
                    </TableCell>
                    <TableCell align='center'>
                      {diretores && cliente.diretor
                        ? diretores.find(
                            (item) => item.post_id == cliente.diretor
                          ).post_title
                        : 'Não informado'}
                    </TableCell>
                    <TableCell align='center'>
                      {formatarDataHora(cliente.alteradoEm)}
                    </TableCell>
                    <TableCell align='center'>
                      {formatarDataHora(cliente.criadoEm)}
                    </TableCell>
                    <TableCell className='text-end'>
                      <Acoes cliente={cliente} />
                    </TableCell>
                  </TableRow>
                ))
              ) : listagem === null || diretores === null ? (
                <TableRow>
                  <TableCell
                    className='text-center'
                    colSpan={7}
                    sx={{ height: '247.5px' }}
                  >
                    <Typography gutterBottom>
                      Houve um erro ao carregar a listagem!
                    </Typography>
                    <Button onClick={tentarNovamente}>Tentar novamente</Button>
                  </TableCell>
                </TableRow>
              ) : (
                <ListaSkeleton />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
}

Lista.propTypes = {
  listagem: PropTypes.array,
  tentarNovamente: PropTypes.func.isRequired,
};

/**
 * @return {void} lista de clientes cadastrados.
 */
export default function Paroquias() {
  const [lista] = useParoquias();
  const [, , , diretoresFetch, , , paroquiasFetch] = useFuncoes();

  /**
   */
  function geral() {
    diretoresFetch();
    paroquiasFetch();
  }

  useEffect(geral, []);

  return (
    <NovaParoquiaProvider>
      <VisualizarParoquiaProvider>
        <AlterarProvider>
          <ExcluirClienteProvider>
            <Grid item xs={12} md={8} lg={9}>
              <Grid item xs={12}>
                <Lista listagem={lista} tentarNovamente={geral} />
              </Grid>
            </Grid>
          </ExcluirClienteProvider>
        </AlterarProvider>
      </VisualizarParoquiaProvider>
    </NovaParoquiaProvider>
  );
}
