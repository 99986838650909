import { Button, Stack, TableContainer, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFuncoes } from '../../hooks/FuncoesGerais';
import { usePerguntas } from '../../hooks/listagem/Perguntas';
import { formatarDataHora } from '../../uteis/Funcoes';
import { perguntasLista } from '../../uteis/Parametros';
import Acoes from './Acoes/Acoes';
import { AlterarPerguntaProvider } from './Acoes/Alterar';
import { ExcluirPerguntaProvider } from './Acoes/Excluir';
import { NovaPergunta } from './Acoes/Novo';
import { ReordenarProvider, useReordenar } from './Acoes/Reordenar';

const titulo = 'Perguntas';
const botaoNovoItem = 'Nova pergunta';

/**
 * @return {object}
 */
function ColunasHead() {
  return (
    <TableRow>
      <TableCell align='center'>Id</TableCell>
      <TableCell align='center'>Ordem</TableCell>
      <TableCell align='center'>Tipo</TableCell>
      <TableCell align='center'>Pergunta</TableCell>
      <TableCell align='center'>Alternativas</TableCell>
      <TableCell align='center'>Criado em</TableCell>
      <TableCell align='center'></TableCell>
    </TableRow>
  );
}

/**
 * @return {object}
 */
function ListaSkeleton() {
  return Array(5)
    .fill()
    .map((item, index) => (
      <TableRow key={`skeleton-usuario-item-${index}`}>
        <TableCell align='center'>
          <Skeleton variant='text' width={'2ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'2ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'6ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'19ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'15ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center'>
          <Skeleton
            variant='rounded'
            width={'102.36px'}
            height={'36.5px'}
            style={{ float: 'inline-end' }}
          />
        </TableCell>
      </TableRow>
    ));
}

/**
 * @param {array} listagem
 * @param {func} tentarNovamente
 * @return {object}
 */
function Lista({ listagem, tentarNovamente }) {
  const [nova, setNova] = useState(false);
  const [setReordenarAberto] = useReordenar();

  /**
   */
  function handleAbrirNovaPergunta() {
    setNova(true);
  }

  /**
   */
  function handleFechar() {
    setNova(false);
  }

  /**
   */
  function handleReordenar() {
    setReordenarAberto(true);
  }

  return (
    <React.Fragment>
      {nova && <NovaPergunta handleFechar={handleFechar} />}
      <Stack p={2} justifyContent='space-between' alignItems='center' direction='row'>
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          {titulo}
        </Typography>
        <Stack direction={'row'} spacing={2}>
          <Button variant='contained' onClick={handleReordenar}>
            Reordenar
          </Button>
          <Button variant='contained' onClick={handleAbrirNovaPergunta}>
            {botaoNovoItem}
          </Button>
        </Stack>
      </Stack>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <TableContainer
          sx={{ border: '1px solid #0000001a', borderRadius: '3px' }}
        >
          <Table size='small' aria-label='caption table'>
            <TableHead>
              <ColunasHead />
            </TableHead>
            <TableBody>
              {listagem ? (
                listagem
                  .sort((a, b) => a.ordem - b.ordem)
                  .map(
                    (
                      { id, tipo, ordem, pergunta, alternativas, criadoEm },
                      index
                    ) => (
                      <TableRow
                        key={`${titulo.toLocaleLowerCase()}-item-map-${index}`}
                      >
                        <TableCell align='center'>{id}</TableCell>
                        <TableCell align='center'>{ordem}</TableCell>
                        <TableCell align='center'>
                          {perguntasLista[tipo]}
                        </TableCell>
                        <TableCell align='center'>{pergunta}</TableCell>
                        <TableCell align='center'>
                          {alternativas
                            ? JSON.parse(alternativas).length
                            : 'Texto'}
                        </TableCell>
                        <TableCell align='center'>
                          {formatarDataHora(criadoEm)}
                        </TableCell>
                        <TableCell className='text-end'>
                          <Acoes id={id} />
                        </TableCell>
                      </TableRow>
                    )
                  )
              ) : listagem === null ? (
                <TableRow>
                  <TableCell
                    className='text-center'
                    colSpan={7}
                    sx={{ height: '247.5px' }}
                  >
                    <Typography gutterBottom>
                      Houve um erro ao carregar a listagem!
                    </Typography>
                    <Button onClick={tentarNovamente}>Tentar novamente</Button>
                  </TableCell>
                </TableRow>
              ) : (
                <ListaSkeleton />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
}

Lista.propTypes = {
  listagem: PropTypes.array,
  tentarNovamente: PropTypes.func.isRequired,
};

/**
 * @return {void} lista de clientes cadastrados.
 */
export default function Perguntas() {
  const [perguntas] = usePerguntas();
  const [, , perguntasFetch] = useFuncoes();

  useEffect(() => {
    perguntasFetch();
  }, []);

  return (
    <AlterarPerguntaProvider>
      <ExcluirPerguntaProvider>
        <ReordenarProvider>
          <Grid item xs={12} md={8} lg={9}>
            <Grid item xs={12}>
              <Lista listagem={perguntas} tentarNovamente={perguntasFetch} />
            </Grid>
          </Grid>
        </ReordenarProvider>
      </ExcluirPerguntaProvider>
    </AlterarPerguntaProvider>
  );
}
