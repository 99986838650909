import ForestIcon from '@mui/icons-material/Forest';
import React from 'react';

// Tema
export const paramTemaCorPrimaria = '#2b3643';
export const paramTemaCorSecundaria = '#7c7c7c';

// Urls
export const paramApiUrl = 'https://api.operadorajpf.com.br/';
export const paramSiteUrl = 'https://painel.operadorajpf.com.br/';

export const paramPrefixo = 'jpf';

// Cookies
export const paramCookieAutenticacao = `${paramPrefixo}Auth`;
export const paramCookieMaster = `${paramPrefixo}Master`;

export const paramStatus = {
  1: 'O',
  2: 'K',
};

export const paramItemsMenu = [
  {
    url: '/atendimentos',
    icone: <ForestIcon />,
    titulo: 'Atendimentos',
  },
  {
    url: '/clientes',
    icone: <ForestIcon />,
    titulo: 'Clientes',
  },
  {
    url: '/diretores',
    icone: <ForestIcon />,
    titulo: 'Diretores',
  },
  {
    url: '/paroquias',
    icone: <ForestIcon />,
    titulo: 'Paroquias',
  },
  {
    url: '/roteiros',
    icone: <ForestIcon />,
    titulo: 'Roteiros',
  },
];

export const paramItemsMenuAdmin = [
  {
    url: '/perguntas',
    icone: <ForestIcon />,
    titulo: 'Perguntas',
  },
  {
    url: '/usuarios',
    icone: <ForestIcon />,
    titulo: 'Usuarios',
  },
];

export const mobile = window.innerWidth < 768;

export const pagoRecebido = ['Não', 'Sim'];

export const perguntasLista = {
  1: 'Múltipla escolha',
  2: 'Caixa de seleção',
  3: 'Resposta',
};

export const destinoLista = {
  1: 'nacional',
  2: 'internacional',
};

export const boolList = {
  0: 'Não informado',
  1: 'Sim',
  2: 'Não',
};

export const statusList = {
  1: 'Em andamento',
  2: 'Concluído',
  3: 'Cancelado',
};

export const statusClassList = {
  1: 'jpf-status-andamento',
  2: 'jpf-status-concluido',
  3: 'jpf-status-excluido',
};

export const listaBotaoCpf = {
  0: 'Verificar',
  1: 'Verificar',
  2: 'Não existe',
  3: 'Já Existe',
};

export const usuarioTipoLista = {
  1: 'Atendente',
  2: 'Master',
};
