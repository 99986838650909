import PropTypes from 'prop-types';
import React, { createContext, useEffect } from 'react';
import { useApi } from './Api';

export const AuthContext = createContext();

/**
 * Provider para a Autenticação
 * @param {any} children componente children
 * @return {children} Autenticação.
 */
export function SessaoProvider({ children }) {
  const ApiController = useApi();
  const api = new ApiController();

  useEffect(() => {
    api.post('autenticacao/sessao/validar');
  }, []);

  return <AuthContext.Provider value={null}>{children}</AuthContext.Provider>;
}
SessaoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
