import {
  Button,
  Paper,
  Stack,
  TableContainer,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFuncoes } from '../../hooks/FuncoesGerais';
import { useUsuarios } from '../../hooks/listagem/Usuarios';
import { formatarDataHora } from '../../uteis/Funcoes';
import { mobile, usuarioTipoLista } from '../../uteis/Parametros';
import Acoes from './Acoes';
import { AlterarUsuarioProvider } from './Acoes/Alterar';
import { ExcluirUsuarioProvider } from './Acoes/Excluir';
import { NovoUsuarioProvider, useNovoUsuario } from './Acoes/Novo';

const titulo = 'Usuários';
const botaoNovoItem = 'Novo Usuário';

/**
 * @return {object}
 */
function ColunasHead() {
  return (
    <TableRow>
      <TableCell align='center'>Id</TableCell>
      <TableCell align='center'>Tipo</TableCell>
      <TableCell align='center'>Usuário</TableCell>
      <TableCell align='center'>Última alteração</TableCell>
      <TableCell align='center'>Data de cadastro</TableCell>
      <TableCell align='center'></TableCell>
    </TableRow>
  );
}

/**
 * @return {object}
 */
function ListaSkeleton() {
  return Array(5)
    .fill()
    .map((item, index) => (
      <TableRow key={`skeleton-usuario-item-${index}`}>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'2ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'7ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton
            variant='rounded'
            width={'102.36px'}
            height={'36.5px'}
            style={{ float: 'inline-end' }}
          />
        </TableCell>
      </TableRow>
    ));
}

/**
 * @param {array} listagem
 * @param {func} tentarNovamente
 * @return {object}
 */
function Lista({ listagem, tentarNovamente }) {
  const [setNovoUsuarioAberto] = useNovoUsuario();

  /**
   */
  function handleNovoUsuario() {
    setNovoUsuarioAberto(true);
  }

  return (
    <React.Fragment>
      <Stack
        p={2}
        justifyContent={mobile ? 'unset' : 'space-between'}
        alignItems={mobile ? 'unset' : 'center'}
        direction={mobile ? 'column' : 'row'}
      >
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          {titulo}
        </Typography>
        <Button variant='contained' onClick={handleNovoUsuario}>
          {botaoNovoItem}
        </Button>
      </Stack>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <TableContainer
          sx={{ border: '1px solid #0000001a', borderRadius: '3px' }}
        >
          <Table size='small' aria-label='caption table'>
            <TableHead>
              <ColunasHead />
            </TableHead>
            <TableBody>
              {listagem ? (
                listagem.map((usuario, index) => (
                  <TableRow
                    key={`${titulo.toLocaleLowerCase()}-item-map-${index}`}
                  >
                    <TableCell align='center'>{usuario.id}</TableCell>
                    <TableCell align='center'>
                      {usuarioTipoLista[usuario.tipo]}
                    </TableCell>
                    <TableCell align='center'>{usuario.usuario}</TableCell>
                    <TableCell align='center'>
                      {formatarDataHora(usuario.alteradoEm)}
                    </TableCell>
                    <TableCell align='center'>
                      {formatarDataHora(usuario.criadoEm)}
                    </TableCell>
                    <TableCell className='text-end'>
                      <Acoes usuario={usuario} />
                    </TableCell>
                  </TableRow>
                ))
              ) : listagem === null ? (
                <TableRow>
                  <TableCell
                    className='text-center'
                    colSpan={5}
                    sx={{ height: '247.5px' }}
                  >
                    <Typography gutterBottom>
                      Houve um erro ao carregar a listagem!
                    </Typography>
                    <Button onClick={tentarNovamente}>Tentar novamente</Button>
                  </TableCell>
                </TableRow>
              ) : (
                <ListaSkeleton />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
}

Lista.propTypes = {
  listagem: PropTypes.array,
  tentarNovamente: PropTypes.func.isRequired,
};

/**
 * @return {void} lista de clientes cadastrados.
 */
export default function Usuarios() {
  const [usuarios] = useUsuarios();
  const [, usuariosFetch] = useFuncoes();

  useEffect(() => {
    usuariosFetch();
  }, []);

  return (
    <NovoUsuarioProvider>
      <AlterarUsuarioProvider>
        <ExcluirUsuarioProvider>
          <Grid item xs={12} md={8} lg={9}>
            <Grid item xs={12}>
              <Lista listagem={usuarios} tentarNovamente={usuariosFetch} />
            </Grid>
          </Grid>
        </ExcluirUsuarioProvider>
      </AlterarUsuarioProvider>
    </NovoUsuarioProvider>
  );
}
