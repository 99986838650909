/* eslint-disable no-unused-vars */
import CollectionsIcon from '@mui/icons-material/Collections';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  DialogActions,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ElementoPergunta from '../../../componentes/ElementoPergunta/ElementoPergunta';
import { useApi } from '../../../hooks/Api';
import { useAtendimentos } from '../../../hooks/listagem/Atendimentos';
import { useClientes } from '../../../hooks/listagem/Clientes';
import { usePerguntas } from '../../../hooks/listagem/Perguntas';
import { useRoteiros } from '../../../hooks/listagem/Roteiros';
import { useUsuarios } from '../../../hooks/listagem/Usuarios';
import {
  converterData,
  filtrarPorNome,
  inserirItemNoState,
} from '../../../uteis/Funcoes';
import { destinoLista } from '../../../uteis/Parametros';
import { useAlterarCliente } from '../../Clientes/Acoes/Alterar';
import { useNovoCliente } from '../../Clientes/Acoes/Novo';
import CampoNascimento from '../../../componentes/Campo/Nascimento';
import { useVisualizarCliente } from '../../Clientes/Acoes/Visualizar';
import VisualizarOnibus from '../../../componentes/VisualizarOnibus/VisualizarOnibus';

const url = `atendimentos/novo`;
const titulo = `Novo Atendimento`;
const botaoCadastrarMensagem = 'Cadastrar';
const botaoCancelarMensagem = 'Fechar';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function NovoProvider({ children }) {
  const [setNovoCliente] = useNovoCliente();
  const [setAlterarCliente] = useAlterarCliente();

  const [aberto, setAberto] = useState(false);
  const [enviando, setEnviando] = useState(false);
  const [, setLista] = useAtendimentos();

  const [destino, setDestino] = useState();
  const [roteiro, setRoteiro] = useState();

  const [viajou, setViajou] = useState();
  const [anexos, setAnexos] = useState([]);

  const [usuarios] = useUsuarios();
  const [perguntas] = usePerguntas();
  const [clientes] = useClientes();
  const [roteiros] = useRoteiros();
  const [atendimentos] = useAtendimentos();

  const [clienteS, setClienteS] = useState();

  const [setVisualizarCliente] = useVisualizarCliente();

  const [assentosIndisponiveis, setAssentosIndisponiveis] = useState();

  const validacaoGeral = Boolean(
    usuarios && perguntas && clientes && roteiros && atendimentos
  );

  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleVisualizarCliente() {
    setVisualizarCliente(clienteS);
  }

  /**
   */
  function handleFechar() {
    setAberto(false);
    setAnexos();
    setClienteS();
  }

  /**
   * @param {object} e
   */
  function handleDestino(e) {
    setDestino(e.target.value);
  }

  /**
   * @param {object} e
   */
  function handleRoteiro(e) {
    setRoteiro(e.target.value);
  }

  /**
   * @param {object} e
   */
  function handleViajou(e) {
    setViajou(e.target.value);
  }

  /**
   * @param {object} e
   */
  function handleUpload(e) {
    e.preventDefault();

    setAnexos(Array.from(e.target.files));
  }

  /**
   */
  function handleAlterarCliente() {
    setAlterarCliente(clienteS);
    handleFechar();
  }

  /**
   */
  function handleNovoCliente() {
    setNovoCliente(true);
  }

  /**
   * @param {array} array
   * @return {array}
   */
  function converterArrayEmAutocomplete(array) {
    const clone = structuredClone(array).sort(filtrarPorNome);

    clone.forEach((item, index) => {
      clone[index] = { label: item.nome, value: item.id };
    });

    return clone;
  }

  /**
   * @param {number} assento
   * @return {boolean}
   */
  function handleAssentoDisponivel(assento) {
    return assentosIndisponiveis.find((item) => item.assento == assento);
  }

  /**
   * @param {object} e
   * @param {object} nE
   */
  function handleCliente(e, nE) {
    if (nE) {
      setClienteS(clientes.find((item) => item.id === nE.value));
    } else setClienteS();
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = new FormData(event.currentTarget);

    if (clienteS) {
      data.append('cliente', clienteS.id);
    }

    api
      .post(url, data)
      .then((r) => {
        setLista((prev) => inserirItemNoState(r, prev));

        toast.success(`Atendimento cadastrado com sucesso`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);

        if (clienteS.cpf == null && data.viajou === 1) {
          handleAlterarCliente();
        } else {
          handleFechar();
        }
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  useEffect(() => {
    if (roteiro) {
      setAssentosIndisponiveis(
        atendimentos
          .filter((item) => item.roteiro == roteiro)
          .map((item) => ({ assento: item.assento, passageiro: item.cliente }))
      );
    } else {
      setAssentosIndisponiveis();
    }
  }, [roteiro]);

  return (
    <Ctx.Provider value={[setAberto]}>
      {validacaoGeral && (
        <Dialog
          open={aberto}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle>{titulo}</DialogTitle>
          <DialogContent sx={{ overflow: 'visible' }}>
            <Box component='form' autoComplete='off' onSubmit={handleEnviar}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Button onClick={handleNovoCliente} variant='contained'>
                    Novo cliente
                  </Button>
                </Grid>
                <Grid item xs={12} sm={clienteS ? 8 : 12}>
                  <Autocomplete
                    defaultValue={''}
                    onChange={handleCliente}
                    options={converterArrayEmAutocomplete(clientes)}
                    renderInput={(params) => (
                      <TextField {...params} label='Cliente' required />
                    )}
                  />
                </Grid>
                {clienteS && (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Button
                      variant='contained'
                      onClick={handleVisualizarCliente}
                    >
                      Visualizar cliente
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} sm={destino ? 6 : 12}>
                  <FormControl fullWidth>
                    <InputLabel>Destino *</InputLabel>
                    <Select
                      label='Destino *'
                      name='destino'
                      required
                      defaultValue={''}
                      onChange={handleDestino}
                    >
                      <MenuItem value={1}>Nacional</MenuItem>
                      <MenuItem value={2}>Internacional</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {destino && (
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Roteiro *</InputLabel>
                      <Select
                        label='Roteiro *'
                        name='roteiro'
                        required
                        defaultValue={''}
                        onChange={handleRoteiro}
                      >
                        {structuredClone(roteiros)
                          .filter(
                            (item) => item.post_tipo === destinoLista[destino]
                          )
                          .map(
                            (
                              {
                                post_id: postId,
                                post_title: postTitle,
                                post_data_retorno: postDataRetorno,
                                post_texto1: postPadre,
                              },
                              index
                            ) => (
                              <MenuItem
                                key={`item-map-cliente-${index}`}
                                value={postId}
                                component={'tablerow'}
                              >
                                {converterData(postDataRetorno)} | {postTitle} |{' '}
                                {postPadre ? postPadre : 'Não informado'}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} sm={12}>
                  <Divider sx={{ opacity: 1 }} />
                </Grid>
                {perguntas.map((props, index) => (
                  <Grid key={`item-map-pergunta-${index}`} item xs={12} sm={12}>
                    <ElementoPergunta
                      key={`item-map-pergunta-${index}`}
                      {...props}
                    />
                  </Grid>
                ))}
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    name='observacao'
                    label='Observação'
                    autoComplete='off'
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Divider sx={{ opacity: 1 }} />
                </Grid>
                <Grid item xs={12} sm={viajou != null ? 6 : 12}>
                  <FormControl fullWidth>
                    <InputLabel>Vai viajar?</InputLabel>
                    <Select
                      label='Vai viajar?'
                      name='viajou'
                      defaultValue={''}
                      onChange={handleViajou}
                    >
                      <MenuItem value={1}>Sim</MenuItem>
                      <MenuItem value={2}>Não</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {viajou === 2 && (
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ width: '100%' }} variant='outlined'>
                      <InputLabel htmlFor='retornar_em'>
                        Retornar contato em
                      </InputLabel>
                      <OutlinedInput
                        name='retornar_em'
                        inputComponent={CampoNascimento}
                        label='Retornar contato em'
                        placeholder='XX/XX/XXXX'
                      />
                    </FormControl>
                  </Grid>
                )}
                {viajou === 1 ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel>Assento *</InputLabel>
                        <Select
                          label='Assento *'
                          name='assento'
                          required
                          defaultValue={''}
                        >
                          {Array.from({ length: 56 }, (e, i) => i + 1).map(
                            (item, index) => (
                              <MenuItem
                                key={`item-map-assento-${index}`}
                                value={item}
                                disabled={handleAssentoDisponivel(item)}
                              >
                                {`${item} ${
                                  handleAssentoDisponivel(item)
                                    ? `- ${clientes.find((itemS) => itemS.id == assentosIndisponiveis.find((ass) => ass.assento == item).passageiro).nome}`
                                    : ''
                                }`}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <VisualizarOnibus />
                    </Grid>
                  </>
                ) : (
                  <input name='assento' hidden value={null} />
                )}
                {anexos && (
                  <Grid item xs={12}>
                    <Stack sx={{ overflow: 'scroll', width: '100%' }}>
                      <Stack spacing={2} direction={'row'} mb={2}>
                        {anexos.map((item, index) => (
                          <a
                            key={`item-imagem-item-${index}`}
                            href={`${URL.createObjectURL(item)}`}
                            target='_blank'
                            rel='noreferrer'
                            style={{ width: '145px', height: '145px' }}
                          >
                            <img
                              src={`${URL.createObjectURL(item)}`}
                              loading='lazy'
                              style={{
                                width: '145px',
                                height: '145px',
                                objectFit: 'cover',
                              }}
                            />
                          </a>
                        ))}
                      </Stack>
                    </Stack>
                  </Grid>
                )}
              </Grid>
              <DialogActions sx={{ p: 0, mt: 3 }}>
                <Button
                  component='label'
                  type='button'
                  variant='contained'
                  color='secondary'
                  disabled={enviando}
                  startIcon={<CollectionsIcon />}
                  sx={{ margin: '0 auto', marginLeft: 0 }}
                >
                  Adicionar anexos
                  <input
                    hidden
                    type='file'
                    accept='image/png, image/jpg, image/jpeg, image/webp'
                    name='anexo'
                    multiple
                    onChange={handleUpload}
                  />
                </Button>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={enviando}
                >
                  {botaoCadastrarMensagem}
                </LoadingButton>
                <Button
                  type='button'
                  variant='outlined'
                  color='secondary'
                  onClick={handleFechar}
                  disabled={enviando}
                >
                  {botaoCancelarMensagem}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

NovoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useNovo = () => {
  return useContext(Ctx);
};
