import ClearIcon from '@mui/icons-material/Clear';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../../hooks/Api';
import { usePerguntas } from '../../../hooks/listagem/Perguntas';
import { perguntasLista } from '../../../uteis/Parametros';

const url = 'perguntas/nova';
const titulo = 'Nova pergunta';
const botaoCadastrarMensagem = 'Cadastrar pergunta';
const botaoCancelarMensagem = 'Fechar';

/**
 * Hooks manager
 * @param {any} children
 * @param {function} handleFechar
 * @return {children} Hooks.
 */
export function NovaPergunta({ children, handleFechar }) {
  const [enviando, setEnviando] = useState(false);
  const [tipoPergunta, setTipoPergunta] = useState(3);
  const [alternativas, setAlternativas] = useState(['Opção 1']);
  const [, setPerguntas] = usePerguntas();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   * @param {object} e
   */
  function handleTipoPergunta(e) {
    setTipoPergunta(e.target.value);
  }

  /**
   */
  function handleAdicionarAlternativa() {
    if (alternativas) {
      const seq = alternativas.length + 1;

      if (alternativas[0] === 'Sim') {
        setAlternativas((prev) => [...prev, `Não`]);
      } else {
        setAlternativas((prev) => [...prev, `Opção ${seq}`]);
      }
    }
  }

  /**
   * @param {number} index
   */
  function handleRemoverAlternativa(index) {
    if (alternativas.length > 1) {
      const prevAlternativas = structuredClone(alternativas);

      prevAlternativas.splice(index, 1);

      setAlternativas(prevAlternativas);
    }
  }

  /**
   * @param {object} e
   */
  function handleAlterarAlternativa(e) {
    const prevAlternativas = structuredClone(alternativas);

    prevAlternativas[e.target.getAttribute('index')] = e.target.value;

    setAlternativas(prevAlternativas);
  }

  /**
   * @param {object} e eventos
   */
  function handleEnviar(e) {
    e.preventDefault();
    setEnviando(true);
    const data = new FormData(e.currentTarget);

    api
      .post(url, data)
      .then((r) => {
        setPerguntas((prev) => [...prev, r]);

        toast.success('Perguntada cadastrada com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return (
    <Dialog
      open={true}
      onClose={handleFechar}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth={'sm'}
    >
      <DialogTitle id='alert-dialog-title'>{titulo}</DialogTitle>
      <DialogContent>
        <Box
          component='form'
          autoComplete='off'
          noValidate
          sx={{ mt: 3 }}
          onSubmit={handleEnviar}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel>Tipo</InputLabel>
                <Select
                  label='Tipo'
                  name='tipo'
                  required
                  onChange={handleTipoPergunta}
                >
                  <MenuItem value={1}>{perguntasLista[1]}</MenuItem>
                  <MenuItem value={2}>{perguntasLista[2]}</MenuItem>
                  <MenuItem value={3}>{perguntasLista[3]}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                fullWidth
                name='pergunta'
                label='Pergunta'
                id='pergunta'
                autoComplete='off'
              />
            </Grid>
            {tipoPergunta === 1 && (
              <>
                {alternativas &&
                  alternativas.map((item, index) => (
                    <Grid
                      key={`item-map-alternativa-${index}`}
                      item
                      sx={12}
                      sm={12}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      <FormControlLabel
                        control={<Radio checked={false} disableRipple />}
                        sx={{ width: '100%' }}
                        label={
                          <TextField
                            variant='standard'
                            type='text'
                            name='alternativas[]'
                            value={item}
                            autoFocus
                            inputProps={{ index }}
                            onChange={handleAlterarAlternativa}
                          />
                        }
                      />
                      {alternativas.length > 1 && (
                        <IconButton
                          aria-label='delete'
                          onClick={() => {
                            handleRemoverAlternativa(index);
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                    </Grid>
                  ))}
                <Grid
                  item
                  sx={12}
                  sm={12}
                  display={'flex'}
                  alignItems={'center'}
                >
                  <FormControlLabel
                    control={<Radio checked={false} disableRipple />}
                    label={
                      <TextField
                        variant='standard'
                        placeholder='Adicionar opção'
                        type='text'
                        onFocus={handleAdicionarAlternativa}
                      />
                    }
                  />
                </Grid>
              </>
            )}
            {tipoPergunta === 2 && (
              <>
                {alternativas &&
                  alternativas.map((item, index) => (
                    <Grid
                      key={`item-map-alternativa-${index}`}
                      item
                      sx={12}
                      sm={12}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      <FormControlLabel
                        control={<Checkbox checked={false} disableRipple />}
                        sx={{ width: '100%' }}
                        label={
                          <TextField
                            variant='standard'
                            type='text'
                            name='alternativas[]'
                            value={item}
                            autoFocus
                            inputProps={{ index }}
                            onChange={handleAlterarAlternativa}
                          />
                        }
                      />
                      {alternativas.length > 1 && (
                        <IconButton
                          aria-label='delete'
                          onClick={() => {
                            handleRemoverAlternativa(index);
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                    </Grid>
                  ))}
                <Grid
                  item
                  sx={12}
                  sm={12}
                  display={'flex'}
                  alignItems={'center'}
                >
                  <FormControlLabel
                    control={<Checkbox checked={false} disableRipple />}
                    sx={{ width: '100%' }}
                    label={
                      <TextField
                        variant='standard'
                        placeholder='Adicionar opção'
                        type='text'
                        onFocus={handleAdicionarAlternativa}
                      />
                    }
                  />
                </Grid>
              </>
            )}
          </Grid>
          <LoadingButton
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3 }}
            loading={enviando}
          >
            {botaoCadastrarMensagem}
          </LoadingButton>
          <Button
            type='button'
            fullWidth
            variant='outlined'
            sx={{ mt: 2, mb: 2 }}
            onClick={handleFechar}
            disabled={enviando}
          >
            {botaoCancelarMensagem}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

NovaPergunta.propTypes = {
  handleFechar: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
