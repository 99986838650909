import { LoadingButton } from '@mui/lab';
import {
  Box,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../../hooks/Api';
import { useDiretores } from '../../../hooks/listagem/Diretores';
import { useParoquias } from '../../../hooks/listagem/Paroquias';
import { inserirItemNoState } from '../../../uteis/Funcoes';
import CampoTelefone from '../../../componentes/Campo/Telefone';

const url = `paroquias/novo`;
const titulo = `Nova Paróquia`;
const botaoCadastrarMensagem = 'Cadastrar';
const botaoCancelarMensagem = 'Fechar';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function NovaParoquiaProvider({ children }) {
  const [aberto, setAberto] = useState(false);
  const [enviando, setEnviando] = useState(false);
  const [diretores] = useDiretores();
  const [, setLista] = useParoquias();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setAberto(false);
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = new FormData(event.currentTarget);

    api
      .post(url, data)
      .then((r) => {
        setLista((prev) => inserirItemNoState(r, prev));

        toast.success(`Paróquia cadastrado com sucesso`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return (
    <Ctx.Provider value={[setAberto]}>
      <Dialog
        open={aberto}
        onClose={handleFechar}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle>{titulo}</DialogTitle>
        <DialogContent sx={{ overflow: 'visible' }}>
          <Box component='form' autoComplete='off' onSubmit={handleEnviar}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name='nome'
                  label='Nome'
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ width: '100%' }} variant='outlined'>
                  <InputLabel htmlFor='fone1'>Fone *</InputLabel>
                  <OutlinedInput
                    name='fone'
                    inputComponent={CampoTelefone}
                    label='Fone *'
                    placeholder='(XX) 9XXXX-XXXX'
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name='cidade'
                  label='Cidade'
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Diretor</InputLabel>
                  <Select label='Diretor' name='diretor' defaultValue={''}>
                    {diretores &&
                      diretores.map((item, index) => (
                        <MenuItem
                          key={`item-map-diretor-${index}`}
                          value={item.post_id}
                        >
                          {item.post_title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  fullWidth
                  name='endereco'
                  label='Endereço'
                  autoComplete='off'
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  name='observacao'
                  label='Observação'
                  autoComplete='off'
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
            <DialogActions sx={{ p: 0, mt: 3 }}>
              <LoadingButton
                type='submit'
                variant='contained'
                loading={enviando}
              >
                {botaoCadastrarMensagem}
              </LoadingButton>
              <Button
                type='button'
                variant='outlined'
                color='secondary'
                onClick={handleFechar}
                disabled={enviando}
              >
                {botaoCancelarMensagem}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
      {children}
    </Ctx.Provider>
  );
}

NovaParoquiaProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useNovaParoquia = () => {
  return useContext(Ctx);
};
