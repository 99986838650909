import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { StyledEngineProvider } from '@mui/material/styles';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import App from './App';
import { HooksProvider } from './hooks/HooksManager';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('jpf-root'));
root.render(
  <>
    <ErrorBoundary
      fallback={<p>Algo deu errado</p>}
      onError={(e) => {
        document.getElementById('error-handler').innerHTML = e;
      }}
    >
      <StyledEngineProvider injectFirst>
        <HooksProvider>
          <App />
        </HooksProvider>
      </StyledEngineProvider>
    </ErrorBoundary>
    <p id='error-handler'></p>
  </>
);
reportWebVitals();
