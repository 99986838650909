import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import { PropTypes } from 'prop-types';
import * as React from 'react';
import { useAlterar } from './Acoes/Alterar';
import { useExcluirCliente } from './Acoes/Excluir';
import { useVisualizarParoquia } from './Acoes/Visualizar';
import ArticleIcon from '@mui/icons-material/Article';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

/**
 * @param {object} cliente
 * @return {void} Ações para o cliente.
 */
export default function Acoes({ cliente }) {
  const [setAlterarCliente] = useAlterar();
  const [setExcluirCliente] = useExcluirCliente();
  const [setVisualizarCliente] = useVisualizarParoquia();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  /**
   * @param {object} e
   */
  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  }

  /**
   */
  function handleClose() {
    setAnchorEl(null);
  }

  /**
   */
  function handleAlterar() {
    setAlterarCliente(cliente);
    handleClose();
  }

  /**
   */
  function handleExcluir() {
    setExcluirCliente(cliente);
    handleClose();
  }

  /**
   */
  function handleVisualizar() {
    setVisualizarCliente(cliente);
    handleClose();
  }

  return (
    <div>
      <Button
        id='demo-customized-button'
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='outlined'
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Ações
      </Button>
      <StyledMenu
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem disableRipple onClick={handleVisualizar}>
          <ArticleIcon />
          Visualizar
        </MenuItem>
        <MenuItem disableRipple onClick={handleAlterar}>
          <EditIcon />
          Alterar
        </MenuItem>
        <MenuItem disableRipple onClick={handleExcluir}>
          <DeleteIcon />
          Excluir
        </MenuItem>
      </StyledMenu>
    </div>
  );
}

Acoes.propTypes = {
  cliente: PropTypes.array.isRequired,
};
