import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from './Api';
import { useAuth } from './Auth';
import { usePerguntas } from './listagem/Perguntas';
import { useUsuarios } from './listagem/Usuarios';
import { useDiretores } from './listagem/Diretores';
import { useClientes } from './listagem/Clientes';
import { useRoteiros } from './listagem/Roteiros';
import { useParoquias } from './listagem/Paroquias';
import { useAtendimentos } from './listagem/Atendimentos';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function FuncoesProvider({ children }) {
  const [, setUsuario] = useAuth();
  const [desconectando, setDesconectando] = useState(false);
  const [usuarios, setUsuarios] = useUsuarios();
  const [perguntas, setPerguntas] = usePerguntas();
  const [diretores, setDiretores] = useDiretores();
  const [clientes, setClientes] = useClientes();
  const [roteiros, setRoteiros] = useRoteiros();
  const [paroquias, setParoquias] = useParoquias();
  const [atendimentos, setAtendimentos] = useAtendimentos();

  const ApiController = useApi();
  const api = new ApiController();

  const navigate = useNavigate();

  /**
   */
  function desconectarUsuario() {
    setDesconectando(true);
    api
      .post('autenticacao/desconectar')
      .then((r) => {
        setDesconectando(false);
        setUsuario(null);
        navigate('/');
      })
      .catch((e) => {
        setDesconectando(false);
      });
  }

  /**
   */
  function usuariosFetch() {
    if (!usuarios) {
      if (usuarios === null) {
        setUsuarios(undefined);
      }

      api
        .post('usuarios/listagem')
        .then((r) => {
          setUsuarios(r);
        })
        .catch((e) => {
          setUsuarios(null);
          console.error(e);
        });
    }
  }

  /**
   */
  function perguntasFetch() {
    if (!perguntas) {
      if (perguntas === null) {
        setPerguntas(undefined);
      }

      api
        .post('perguntas/listagem')
        .then((r) => {
          setPerguntas(r);
        })
        .catch((e) => {
          setPerguntas(null);
          console.error(e);
        });
    }
  }

  /**
   */
  function diretoresFetch() {
    if (!diretores) {
      if (diretores === null) {
        setDiretores(undefined);
      }

      api
        .post('diretores/listagem')
        .then((r) => {
          setDiretores(r);
        })
        .catch((e) => {
          setDiretores(null);
          console.error(e);
        });
    }
  }

  /**
   */
  function clientesFetch() {
    if (!clientes) {
      if (clientes === null) {
        setClientes(undefined);
      }

      api
        .post('clientes/listagem')
        .then((r) => {
          setClientes(r);
        })
        .catch((e) => {
          setClientes(null);
          console.error(e);
        });
    }
  }

  /**
   */
  function roteirosFetch() {
    if (!roteiros) {
      if (roteiros === null) {
        setRoteiros(undefined);
      }

      api
        .post('roteiros/listagem')
        .then((r) => {
          setRoteiros(r);
        })
        .catch((e) => {
          setRoteiros(null);
          console.error(e);
        });
    }
  }

  /**
   */
  function paroquiasFetch() {
    if (!paroquias) {
      if (paroquias === null) {
        setParoquias(undefined);
      }

      api
        .post('paroquias/listagem')
        .then((r) => {
          setParoquias(r);
        })
        .catch((e) => {
          setParoquias(null);
          console.error(e);
        });
    }
  }

  /**
   */
  function atendimentosFetch() {
    if (!atendimentos) {
      if (atendimentos === null) {
        setAtendimentos(undefined);
      }

      api
        .post('atendimentos/listagem')
        .then((r) => {
          setAtendimentos(r);
        })
        .catch((e) => {
          setAtendimentos(null);
          console.error(e);
        });
    }
  }

  return (
    <Ctx.Provider
      value={[
        desconectarUsuario,
        usuariosFetch,
        perguntasFetch,
        diretoresFetch,
        clientesFetch,
        roteirosFetch,
        paroquiasFetch,
        atendimentosFetch
      ]}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={desconectando}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      {children}
    </Ctx.Provider>
  );
}

FuncoesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useFuncoes = () => {
  return useContext(Ctx);
};
