/* eslint-disable react/prop-types */
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { Button, IconButton, Stack, styled } from '@mui/material';
import imageCompression from 'browser-image-compression';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import { base64ToObjectURL } from '../../uteis/Funcoes';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

/**
 * @param {File} blob
 * @return {string}
 */
function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

/**
 * @param {string} name
 * @param {string} defaultValue
 * @return {object}
 */
export function UploadAnexo({ name, defaultValue }) {
  const [loading, setLoading] = useState(false);
  const [anexo, setAnexo] = useState();

  /**
   * @param {object} event
   * @param {function} definir
   * @param {function} carregar
   */
  async function handleImageUpload(event, definir, carregar) {
    carregar(true);
    definir();

    const imageFile = event.target.files[0];

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);

      const base64Converted = await blobToBase64(compressedFile);

      const urlPreview = URL.createObjectURL(compressedFile);

      definir({ url: urlPreview, base64: base64Converted });

      carregar(false);
    } catch (error) {
      carregar(false);
      toast.error(
        'Não foi possível carregar a imagem. Por favor, tente novamente.',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        }
      );
      setAnexo();
    }
  }

  useEffect(() => {
    if (defaultValue) {
      setAnexo({ url: base64ToObjectURL(defaultValue), base64: defaultValue });
    }
  }, [defaultValue]);

  return (
    <Stack direction={'row'} spacing={2}>
      {anexo?.url && (
        <Button
          variant='outlined'
          onClick={() => {
            window.open(anexo?.url);
          }}
        >
          Visualizar
        </Button>
      )}
      <input
        type='text'
        name={name}
        value={anexo?.base64 ? anexo.base64 : ''}
        hidden
      />
      <LoadingButton
        component='label'
        role={undefined}
        variant='contained'
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        loading={loading}
      >
        Carregar
        <VisuallyHiddenInput
          type='file'
          accept='image/png,image/jpg,image/jpeg'
          onChange={(e) => {
            handleImageUpload(e, setAnexo, setLoading);
          }}
        />
      </LoadingButton>
      {anexo && (
        <IconButton
          aria-label='delete'
          onClick={() => {
            setAnexo();
          }}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Stack>
  );
}

UploadAnexo.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
};

export default UploadAnexo;
