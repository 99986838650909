import { DialogActions, Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';

const titulo = `Visualizar`;
const botaoCancelarMensagem = 'Fechar';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function VisualizarParoquiaProvider({ children }) {
  const [alvo, setAlvo] = useState();

  /**
   */
  function handleFechar() {
    setAlvo();
  }

  return (
    <Ctx.Provider value={[setAlvo]}>
      {alvo && (
        <Dialog
          open={alvo != null}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle>{titulo}</DialogTitle>
          <DialogContent sx={{ overflow: 'visible' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name='nome'
                  label='Nome'
                  autoComplete='off'
                  defaultValue={alvo.nome}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name='fone'
                  label='Fone'
                  autoComplete='off'
                  defaultValue={alvo.fone}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  name='endereco'
                  label='Endereço'
                  autoComplete='off'
                  multiline
                  rows={3}
                  defaultValue={alvo.endereco}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  name='observacao'
                  label='Observação'
                  autoComplete='off'
                  multiline
                  rows={3}
                  defaultValue={alvo.observacao}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type='button' variant='outlined' onClick={handleFechar}>
              {botaoCancelarMensagem}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

VisualizarParoquiaProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useVisualizarParoquia = () => {
  return useContext(Ctx);
};
