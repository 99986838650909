import {
  DialogActions,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import ElementoPergunta from '../../../componentes/ElementoPergunta/ElementoPergunta';
import { useAtendimentos } from '../../../hooks/listagem/Atendimentos';
import { useClientes } from '../../../hooks/listagem/Clientes';
import { usePerguntas } from '../../../hooks/listagem/Perguntas';
import { useRoteiros } from '../../../hooks/listagem/Roteiros';
import { useUsuarios } from '../../../hooks/listagem/Usuarios';
import { filtrarPorNome } from '../../../uteis/Funcoes';
import { destinoLista, paramApiUrl } from '../../../uteis/Parametros';

const titulo = `Visualizar Atendimento`;
const botaoCancelarMensagem = 'Fechar';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function VisualizarProvider({ children }) {
  const [alvo, setAlvo] = useState();

  const [usuarios] = useUsuarios();
  const [perguntas] = usePerguntas();
  const [clientes] = useClientes();
  const [roteiros] = useRoteiros();
  const [atendimentos] = useAtendimentos();

  const validacaoGeral = Boolean(
    alvo && usuarios && perguntas && clientes && roteiros && atendimentos
  );

  /**
   */
  function handleFechar() {
    setAlvo();
  }

  return (
    <Ctx.Provider value={[setAlvo]}>
      {validacaoGeral && (
        <Dialog
          open={alvo != null}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle>{titulo}</DialogTitle>
          <DialogContent sx={{ overflow: 'visible' }}>
            <Grid container spacing={2} className='jpf-visualizar-form'>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Cliente</InputLabel>
                  <Select
                    label='Cliente'
                    name='cliente'
                    defaultValue={alvo.cliente}
                  >
                    {structuredClone(clientes)
                      .sort(filtrarPorNome)
                      .map(({ id, nome }, index) => (
                        <MenuItem key={`item-map-cliente-${index}`} value={id}>
                          {nome}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Fone 1'
                  autoComplete='off'
                  disabled
                  value={structuredClone(clientes).find((item) => item.id === alvo.cliente).fone1}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Destino</InputLabel>
                  <Select
                    label='Destino'
                    name='destino'
                    defaultValue={alvo.destino}
                  >
                    <MenuItem value={1}>Nacional</MenuItem>
                    <MenuItem value={2}>Internacional</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Roteiro</InputLabel>
                  <Select
                    label='Roteiro'
                    name='roteiro'
                    defaultValue={alvo.roteiro}
                  >
                    {structuredClone(roteiros)
                      .filter(
                        (item) => item.post_tipo === destinoLista[alvo.destino]
                      )
                      .map(
                        ({ post_id: postId, post_title: postTitle }, index) => (
                          <MenuItem
                            key={`item-map-cliente-${index}`}
                            value={postId}
                          >
                            {postTitle}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Divider sx={{ opacity: 1 }} />
              </Grid>
              {perguntas.map((props, index) => (
                <Grid key={`item-map-pergunta-${index}`} item xs={12} sm={12}>
                  <ElementoPergunta
                    key={`item-map-pergunta-${index}`}
                    {...props}
                    respostas={JSON.parse(alvo.perguntas)}
                  />
                </Grid>
              ))}
              <Grid item xs={12} sm={12}>
                <Divider sx={{ opacity: 1 }} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel>Vai viajar?</InputLabel>
                  <Select
                    label='Vai viajar?'
                    name='viajou'
                    defaultValue={alvo.viajou}
                  >
                    <MenuItem value={1}>Sim</MenuItem>
                    <MenuItem value={2}>Não</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  name='observacao'
                  label='Observação'
                  autoComplete='off'
                  multiline
                  rows={3}
                  defaultValue={alvo.observacao}
                />
              </Grid>
              {alvo.anexos && (
                <Grid item xs={12} className='jpf-visualizar-imagens'>
                  <Stack sx={{ overflow: 'scroll', width: '100%' }}>
                    <Stack spacing={2} direction={'row'} mb={2}>
                      {JSON.parse(alvo.anexos).map((item, index) => (
                        <a
                          key={`item-imagem-item-${index}`}
                          href={`${
                            process.env.NODE_ENV === 'development'
                              ? 'http://localhost:3333'
                              : paramApiUrl
                          }/sistema/anexo/${item}`}
                          target='_blank'
                          rel='noreferrer'
                          style={{ width: '145px', height: '145px' }}
                        >
                          <img
                            src={`${
                              process.env.NODE_ENV === 'development'
                                ? 'http://localhost:3333'
                                : paramApiUrl
                            }/sistema/anexo/${item}`}
                            loading='lazy'
                            style={{
                              width: '145px',
                              height: '145px',
                              objectFit: 'cover',
                            }}
                          />
                        </a>
                      ))}
                    </Stack>
                  </Stack>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type='button' variant='outlined' onClick={handleFechar}>
              {botaoCancelarMensagem}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

VisualizarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAtendimento = () => {
  return useContext(Ctx);
};
