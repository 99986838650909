import { LoadingButton } from '@mui/lab';
import { Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { useApi } from '../../hooks/Api';
import { useAuth } from '../../hooks/Auth';
import { useErrorHandler } from '../../hooks/ErrorHandler';
import Logo from '../../media/logo.png';

/**
 *
 * @return {object}
 */
export default function Entrar() {
  const ApiController = useApi();
  const api = new ApiController();
  const [carregando, setCarregando] = useState(false);
  const [mostrarErro] = useErrorHandler();
  const [, setUsuario] = useAuth();

  const handleSubmit = (event) => {
    event.preventDefault();
    setCarregando(true);
    const dados = new FormData(event.currentTarget);
    api
      .post('/autenticacao/conectar', dados, [400])
      .then((r) => {
        setCarregando(false);
        setUsuario(r.usuario);
      })
      .catch((e) => {
        if (e?.response?.status === 400) {
          mostrarErro([
            'Usuário ou senha incorretos',
            {
              conteudo: [
                'O nome de usuário ou senha informados estão incorretos',
              ],
              acoes: ['Ok'],
              funcoes: [() => {}],
            },
          ]);
        }
        setCarregando(false);
      });
  };

  return (
    <Container component='main' maxWidth='xs'>
      <style>{`body {background-color: #2a426b}`}</style>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <img src={Logo} width={300} className='mb-2' />
        <Paper sx={{ p: 2 }}>
          <Box
            component='form'
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Typography variant='h4' gutterBottom fontWeight={500}>
              Entrar
            </Typography>
            <TextField
              margin='normal'
              required
              fullWidth
              id='usuario'
              label='Nome de usuário'
              name='usuario'
              autoComplete='on'
              autoFocus
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='senha'
              label='Senha'
              type='password'
              id='senha'
              autoComplete='current-password'
            />
            <LoadingButton
              type='submit'
              fullWidth
              variant='contained'
              loading={carregando}
              sx={{ mt: 3, mb: 2 }}
            >
              Entrar
            </LoadingButton>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}
