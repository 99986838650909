import {
  Button,
  Paper,
  Stack,
  TableContainer,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFuncoes } from '../../hooks/FuncoesGerais';
import { useDiretores } from '../../hooks/listagem/Diretores';
import { mobile } from '../../uteis/Parametros';

const titulo = 'Diretores';

/**
 * @return {object}
 */
function ColunasHead() {
  return (
    <TableRow>
      <TableCell align='center'>Id</TableCell>
      <TableCell align='center'>Imagem</TableCell>
      <TableCell align='center'>Diretor</TableCell>
      <TableCell align='center'>Data</TableCell>
      <TableCell align='center'>Ordem</TableCell>
      <TableCell align='center'>Status</TableCell>
    </TableRow>
  );
}

const statusLista = ['Inativo', 'Ativo'];

/**
 * @return {object}
 */
function ListaSkeleton() {
  return Array(5)
    .fill()
    .map((item, index) => (
      <TableRow key={`skeleton-diretor-item-${index}`}>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'2ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='rectangle' width={'80px'} height={'80px'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'19ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'14ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'2ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'2ch'} />
        </TableCell>
      </TableRow>
    ));
}

/**
 * @param {array} listagem
 * @param {func} tentarNovamente
 * @return {object}
 */
function Lista({ listagem, tentarNovamente }) {
  return (
    <React.Fragment>
      <Stack
        p={2}
        justifyContent={mobile ? 'unset' : 'space-between'}
        alignItems={mobile ? 'unset' : 'center'}
        direction={mobile ? 'column' : 'row'}
      >
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          {titulo}
        </Typography>
      </Stack>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <TableContainer
          sx={{ border: '1px solid #0000001a', borderRadius: '3px' }}
        >
          <Table size='small' aria-label='caption table'>
            <TableHead>
              <ColunasHead />
            </TableHead>
            <TableBody>
              {listagem ? (
                listagem.map(
                  (
                    {
                      post_id: postId,
                      post_title: postTitle,
                      post_cover: postCover,
                      post_date: postDate,
                      post_ordem: postOrdem,
                      post_status: postStatus,
                    },
                    index
                  ) => (
                    <TableRow
                      key={`${titulo.toLocaleLowerCase()}-item-map-${index}`}
                    >
                      <TableCell align='center'>{postId}</TableCell>
                      <TableCell align='center'>
                        <img
                          src={`https://operadorajpf.com.br/uploads/${postCover}`}
                          height='80'
                          width='80'
                        />
                      </TableCell>
                      <TableCell align='center'>{postTitle}</TableCell>
                      <TableCell align='center'>{postDate}</TableCell>
                      <TableCell align='center'>{postOrdem}</TableCell>
                      <TableCell align='center'>
                        {statusLista[postStatus]}
                      </TableCell>
                    </TableRow>
                  )
                )
              ) : listagem === null ? (
                <TableRow>
                  <TableCell
                    className='text-center'
                    colSpan={5}
                    sx={{ height: '247.5px' }}
                  >
                    <Typography gutterBottom>
                      Houve um erro ao carregar a listagem!
                    </Typography>
                    <Button onClick={tentarNovamente}>Tentar novamente</Button>
                  </TableCell>
                </TableRow>
              ) : (
                <ListaSkeleton />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
}

Lista.propTypes = {
  listagem: PropTypes.array,
  tentarNovamente: PropTypes.func.isRequired,
};

/**
 * @return {void} lista de clientes cadastrados.
 */
export default function Diretores() {
  const [diretores] = useDiretores();
  const [, , , diretoresFetch] = useFuncoes();

  useEffect(() => {
    diretoresFetch();
  }, []);

  return (
    <Grid item xs={12} md={8} lg={9}>
      <Grid item xs={12}>
        <Lista listagem={diretores} tentarNovamente={diretoresFetch} />
      </Grid>
    </Grid>
  );
}
