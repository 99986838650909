import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TableContainer,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFuncoes } from '../../hooks/FuncoesGerais';
import { useAtendimentos } from '../../hooks/listagem/Atendimentos';
import { useClientes } from '../../hooks/listagem/Clientes';
import { usePerguntas } from '../../hooks/listagem/Perguntas';
import { useRoteiros } from '../../hooks/listagem/Roteiros';
import { useUsuarios } from '../../hooks/listagem/Usuarios';
import { formatarDataHora } from '../../uteis/Funcoes';
import {
  boolList,
  destinoLista,
  mobile,
  statusClassList,
  statusList,
} from '../../uteis/Parametros';
import { AlterarClienteProvider } from '../Clientes/Acoes/Alterar';
import { NovoClienteProvider } from '../Clientes/Acoes/Novo';
import {
  VisualizarClienteProvider
} from '../Clientes/Acoes/Visualizar';
import { NovaParoquiaProvider } from '../Paroquias/Acoes/Novo';
import Acoes from './Acoes';
import { AtualizarProvider } from './Acoes/Atualizar';
import { ExcluirProvider } from './Acoes/Excluir';
import { NovoProvider, useNovo } from './Acoes/Novo';
import { VisualizarProvider } from './Acoes/Visualizar';

const titulo = 'Atendimentos';
const botaoCadastroMensagem = 'Novo Atendimento';

/**
 * @return {object}
 */
function ColunasHead() {
  return (
    <TableRow>
      <TableCell align='center'>Id</TableCell>
      <TableCell align='center'>Status</TableCell>
      <TableCell align='center'>Vendedor</TableCell>
      <TableCell align='center'>Cliente</TableCell>
      <TableCell align='center'>Paroquia</TableCell>
      <TableCell align='center'>Destino</TableCell>
      <TableCell align='center'>Roteiro</TableCell>
      <TableCell align='center'>Viagem confirmada?</TableCell>
      <TableCell align='center'>Última alteração</TableCell>
      <TableCell align='center'>Data de cadastro</TableCell>
      <TableCell align='center'></TableCell>
    </TableRow>
  );
}

/**
 * @return {object}
 */
function ListaSkeleton() {
  return Array(5)
    .fill()
    .map((item, index) => (
      <TableRow key={`skeleton-cliente-item-${index}`}>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'2ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'7ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'7ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'32ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'3ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton
            variant='rounded'
            width={'102.36px'}
            height={'36.5px'}
            style={{ float: 'inline-end' }}
          />
        </TableCell>
      </TableRow>
    ));
}

/**
 * @param {array} listagem
 * @param {func} tentarNovamente
 * @return {object}
 */
function Lista({ listagem, tentarNovamente }) {
  const [setNovoAberto] = useNovo();
  const [usuarios] = useUsuarios();
  const [perguntas] = usePerguntas();
  const [clientes] = useClientes();
  const [roteiros] = useRoteiros();
  const [atendimentos] = useAtendimentos();

  const [filtro, setFiltro] = useState('');
  const [pesquisaFiltro, setPesquisaFiltro] = useState();

  /**
   * @param {object} e
   */
  function handleFiltro(e) {
    setFiltro(e.target.value);
  }

  /**
   */
  function handleLimpar() {
    setFiltro('');
    setPesquisaFiltro();
  }

  /**
   * @param {object} e
   */
  function handlePesquisa(e) {
    console.clear();
    setPesquisaFiltro(e.target.value);
  }

  const validacaoGeral = Boolean(
    usuarios && perguntas && clientes && roteiros && atendimentos
  );

  /**
   */
  function handleNovoItem() {
    setNovoAberto(true);
  }

  return (
    <React.Fragment>
      <Stack
        p={2}
        justifyContent={mobile ? 'unset' : 'space-between'}
        alignItems={mobile ? 'unset' : 'center'}
        direction={mobile ? 'column' : 'row'}
      >
        <Stack direction={'row'} gap={2} alignItems={'center'}>
          <Typography component='h2' variant='h6' color='primary'>
            {titulo}
          </Typography>
        </Stack>
        {validacaoGeral && (
          <Button variant='contained' onClick={handleNovoItem}>
            {botaoCadastroMensagem}
          </Button>
        )}
      </Stack>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Stack
          direction={'row'}
          width={'fit-content'}
          margin={'10px 0'}
          gap={1}
        >
          <FormControl size='small' sx={{ minWidth: '120px' }}>
            <InputLabel>Filtrar por</InputLabel>
            <Select
              label='Filtrar por'
              onChange={handleFiltro}
              value={filtro}
              endAdornment={
                filtro && (
                  <IconButton
                    edge='start'
                    onClick={handleLimpar}
                    sx={{ marginRight: 1 }}
                  >
                    <CloseIcon />
                  </IconButton>
                )
              }
            >
              <MenuItem value={1}>Nome</MenuItem>
              <MenuItem value={2}>CPF</MenuItem>
              <MenuItem value={3}>Fone</MenuItem>
            </Select>
          </FormControl>
          <TextField
            disabled={!filtro}
            label='Pesquisar'
            size='small'
            sx={{ width: '300px' }}
            onChange={handlePesquisa}
          />
        </Stack>
        <TableContainer
          sx={{ border: '1px solid #0000001a', borderRadius: '3px' }}
        >
          <Table size='small' aria-label='caption table'>
            <TableHead>
              <ColunasHead />
            </TableHead>
            <TableBody>
              {listagem && validacaoGeral ? (
                listagem
                  .filter((item) => {
                    if (filtro && pesquisaFiltro) {
                      const itemCliente = clientes.find(
                        (cliente) => cliente.id == item.cliente
                      );
                      console.log(itemCliente, pesquisaFiltro);
                      if (itemCliente != null) {
                        switch (filtro) {
                          case 1:
                            return String(itemCliente.nome)
                              .toLocaleLowerCase()
                              .includes(
                                String(pesquisaFiltro).toLocaleLowerCase()
                              );
                          case 2:
                            return String(itemCliente.cpf)
                              .toLocaleLowerCase()
                              .includes(
                                String(pesquisaFiltro).toLocaleLowerCase()
                              );
                          case 3:
                            return String(itemCliente.fone1)
                              .toLocaleLowerCase()
                              .includes(
                                String(pesquisaFiltro).toLocaleLowerCase()
                              );
                        }
                      }
                    } else return true;
                  })
                  .map((atendimento, index) => (
                    <TableRow
                      key={`${titulo.toLocaleLowerCase()}-item-map-${index}`}
                    >
                      <TableCell align='center'>{atendimento.id}</TableCell>
                      <TableCell align='center'>
                        <Chip
                          className={statusClassList[atendimento.status]}
                          label={statusList[atendimento.status]}
                        />
                      </TableCell>
                      <TableCell align='center'>
                        {usuarios.find((item) => item.id == atendimento.usuario)
                          ? usuarios.find(
                              (item) => item.id == atendimento.usuario
                            ).usuario
                          : 'Não encontrado'}
                      </TableCell>
                      <TableCell align='center'>
                        {clientes.find((item) => item.id == atendimento.cliente)
                          ? clientes.find(
                              (item) => item.id == atendimento.cliente
                            ).nome
                          : 'Não encontrado'}
                      </TableCell>
                      <TableCell align='center'>
                        {destinoLista[atendimento.destino]}
                      </TableCell>
                      <TableCell align='center'>
                        {roteiros.find(
                          (item) => item.post_id == atendimento.roteiro
                        )
                          ? roteiros.find(
                              (item) => item.post_id == atendimento.roteiro
                            ).post_title
                          : 'Não encontrado'}
                      </TableCell>
                      <TableCell align='center'>
                        {boolList[atendimento.viajou]}
                      </TableCell>
                      <TableCell align='center'>
                        {formatarDataHora(atendimento.alteradoEm)}
                      </TableCell>
                      <TableCell align='center'>
                        {formatarDataHora(atendimento.criadoEm)}
                      </TableCell>
                      <TableCell className='text-end'>
                        <Acoes cliente={atendimento} />
                      </TableCell>
                    </TableRow>
                  ))
              ) : listagem === null && validacaoGeral === false ? (
                <TableRow>
                  <TableCell
                    className='text-center'
                    colSpan={10}
                    sx={{ height: '247.5px' }}
                  >
                    <Typography gutterBottom>
                      Houve um erro ao carregar a listagem!
                    </Typography>
                    <Button onClick={tentarNovamente}>Tentar novamente</Button>
                  </TableCell>
                </TableRow>
              ) : (
                <ListaSkeleton />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
}

Lista.propTypes = {
  listagem: PropTypes.array,
  tentarNovamente: PropTypes.func.isRequired,
};

/**
 * @return {void} lista de clientes cadastrados.
 */
export default function Atendimentos() {
  const [lista] = useAtendimentos();
  const [
    ,
    usuariosFetch,
    perguntasFetch,
    ,
    clientesFetch,
    roteirosFetch,
    paroquiasFetch,
    atendimentosFetch,
  ] = useFuncoes();

  /**
   */
  function geralFetch() {
    usuariosFetch();
    perguntasFetch();
    clientesFetch();
    roteirosFetch();
    paroquiasFetch();
    atendimentosFetch();
  }

  useEffect(geralFetch, []);

  return (
    <NovaParoquiaProvider>
      <NovoClienteProvider>
        <AlterarClienteProvider>
          <VisualizarClienteProvider>
            <NovoProvider>
              <VisualizarProvider>
                <AtualizarProvider>
                  <ExcluirProvider>
                    <Grid item xs={12} md={8} lg={9}>
                      <Grid item xs={12}>
                        <Lista listagem={lista} tentarNovamente={geralFetch} />
                      </Grid>
                    </Grid>
                  </ExcluirProvider>
                </AtualizarProvider>
              </VisualizarProvider>
            </NovoProvider>
          </VisualizarClienteProvider>
        </AlterarClienteProvider>
      </NovoClienteProvider>
    </NovaParoquiaProvider>
  );
}
