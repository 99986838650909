import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TableContainer,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFuncoes } from '../../hooks/FuncoesGerais';
import { useClientes } from '../../hooks/listagem/Clientes';
import { useParoquias } from '../../hooks/listagem/Paroquias';
import { formatarDataHora } from '../../uteis/Funcoes';
import { mobile } from '../../uteis/Parametros';
import { NovaParoquiaProvider } from '../Paroquias/Acoes/Novo';
import Acoes from './Acoes';
import { AlterarClienteProvider } from './Acoes/Alterar';
import { ExcluirClienteProvider } from './Acoes/Excluir';
import { NovoClienteProvider, useNovoCliente } from './Acoes/Novo';
import { VisualizarClienteProvider } from './Acoes/Visualizar';

const titulo = 'Clientes';
const botaoNovoItem = 'Novo Cliente';

/**
 * @return {object}
 */
function ColunasHead() {
  return (
    <TableRow>
      <TableCell align='center'>Id</TableCell>
      <TableCell align='center'>Nome</TableCell>
      <TableCell align='center'>Fone 1</TableCell>
      <TableCell align='center'>Fone 2</TableCell>
      <TableCell align='center'>CPF</TableCell>
      <TableCell align='center'>Paróquia</TableCell>
      <TableCell align='center'>Última alteração</TableCell>
      <TableCell align='center'>Data de cadastro</TableCell>
      <TableCell align='center'></TableCell>
    </TableRow>
  );
}

/**
 * @return {object}
 */
function ListaSkeleton() {
  return Array(5)
    .fill()
    .map((item, index) => (
      <TableRow key={`skeleton-cliente-item-${index}`}>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'2ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'7ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'8ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton
            variant='rounded'
            width={'102.36px'}
            height={'36.5px'}
            style={{ float: 'inline-end' }}
          />
        </TableCell>
      </TableRow>
    ));
}

/**
 * @param {array} listagem
 * @param {func} tentarNovamente
 * @return {object}
 */
function Lista({ listagem, tentarNovamente }) {
  const [setNovoClienteAberto] = useNovoCliente();
  const [paroquias] = useParoquias();

  const [filtro, setFiltro] = useState('');
  const [pesquisaFiltro, setPesquisaFiltro] = useState();

  /**
   * @param {object} e
   */
  function handleFiltro(e) {
    setFiltro(e.target.value);
  }

  /**
   */
  function handleLimpar() {
    setFiltro('');
    setPesquisaFiltro();
  }

  /**
   * @param {object} e
   */
  function handlePesquisa(e) {
    console.clear();
    setPesquisaFiltro(e.target.value);
  }

  /**
   */
  function handleNovoCliente() {
    setNovoClienteAberto(true);
  }

  return (
    <React.Fragment>
      <Stack
        p={2}
        justifyContent={mobile ? 'unset' : 'space-between'}
        alignItems={mobile ? 'unset' : 'center'}
        direction={mobile ? 'column' : 'row'}
      >
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          {titulo}
        </Typography>
        {listagem && paroquias && (
          <Button variant='contained' onClick={handleNovoCliente}>
            {botaoNovoItem}
          </Button>
        )}
      </Stack>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Stack
          direction={'row'}
          width={'fit-content'}
          margin={'10px 0'}
          gap={1}
        >
          <FormControl size='small' sx={{ minWidth: '120px' }}>
            <InputLabel>Filtrar por</InputLabel>
            <Select
              label='Filtrar por'
              onChange={handleFiltro}
              value={filtro}
              endAdornment={
                filtro && (
                  <IconButton
                    edge='start'
                    onClick={handleLimpar}
                    sx={{ marginRight: 1 }}
                  >
                    <CloseIcon />
                  </IconButton>
                )
              }
            >
              <MenuItem value={1}>Nome</MenuItem>
              <MenuItem value={2}>CPF</MenuItem>
              <MenuItem value={3}>Fone</MenuItem>
            </Select>
          </FormControl>
          <TextField
            disabled={!filtro}
            label='Pesquisar'
            size='small'
            sx={{ width: '300px' }}
            onChange={handlePesquisa}
          />
        </Stack>
        {pesquisaFiltro}
        <TableContainer
          sx={{ border: '1px solid #0000001a', borderRadius: '3px' }}
        >
          <Table size='small' aria-label='caption table'>
            <TableHead>
              <ColunasHead />
            </TableHead>
            <TableBody>
              {listagem && paroquias ? (
                listagem
                  .filter((item) => {
                    if (filtro && pesquisaFiltro) {
                      switch (filtro) {
                        case 1:
                          return String(item.nome)
                            .toLocaleLowerCase()
                            .includes(pesquisaFiltro.toLocaleLowerCase());
                        case 2:
                          return String(item.cpf)
                            .toLocaleLowerCase()
                            .includes(pesquisaFiltro.toLocaleLowerCase());
                        case 3:
                          return String(item.fone1)
                            .toLocaleLowerCase()
                            .includes(pesquisaFiltro.toLocaleLowerCase());
                      }
                    } else return true;
                  })
                  .map((cliente, index) => (
                    <TableRow
                      key={`${titulo.toLocaleLowerCase()}-item-map-${index}`}
                    >
                      <TableCell align='center'>{cliente.id}</TableCell>
                      <TableCell align='center'>{cliente.nome}</TableCell>
                      <TableCell align='center'>{cliente.fone1}</TableCell>
                      <TableCell align='center'>
                        {cliente.fone2 ? cliente.fone2 : 'Não informado'}
                      </TableCell>
                      <TableCell align='center'>
                        {cliente.cpf ? cliente.cpf : 'Não informado'}
                      </TableCell>
                      <TableCell align='center'>
                        {paroquias?.find((item) => item.id == cliente.paroquia)
                          ?.nome
                          ? paroquias.find(
                              (item) => item.id == cliente.paroquia
                            ).nome
                          : 'Não informado'}
                      </TableCell>
                      <TableCell align='center'>
                        {formatarDataHora(cliente.alteradoEm)}
                      </TableCell>
                      <TableCell align='center'>
                        {formatarDataHora(cliente.criadoEm)}
                      </TableCell>
                      <TableCell className='text-end'>
                        <Acoes cliente={cliente} />
                      </TableCell>
                    </TableRow>
                  ))
              ) : listagem === null || paroquias === null ? (
                <TableRow>
                  <TableCell
                    className='text-center'
                    colSpan={7}
                    sx={{ height: '247.5px' }}
                  >
                    <Typography gutterBottom>
                      Houve um erro ao carregar a listagem!
                    </Typography>
                    <Button onClick={tentarNovamente}>Tentar novamente</Button>
                  </TableCell>
                </TableRow>
              ) : (
                <ListaSkeleton />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
}

Lista.propTypes = {
  listagem: PropTypes.array,
  tentarNovamente: PropTypes.func.isRequired,
};

/**
 * @return {void} lista de clientes cadastrados.
 */
export default function Clientes() {
  const [clientes] = useClientes();
  const [, , , , clientesFetch, , paroquiasFetch] = useFuncoes();

  /**
   */
  function geral() {
    clientesFetch();
    paroquiasFetch();
  }

  useEffect(geral, []);

  return (
    <NovaParoquiaProvider>
      <NovoClienteProvider>
        <VisualizarClienteProvider>
          <AlterarClienteProvider>
            <ExcluirClienteProvider>
              <Grid item xs={12} md={8} lg={9}>
                <Grid item xs={12}>
                  <Lista listagem={clientes} tentarNovamente={geral} />
                </Grid>
              </Grid>
            </ExcluirClienteProvider>
          </AlterarClienteProvider>
        </VisualizarClienteProvider>
      </NovoClienteProvider>
    </NovaParoquiaProvider>
  );
}
