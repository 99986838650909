import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function ParoquiasProvider({ children }) {
  const [lista, setLista] = useState();

  return (
    <Ctx.Provider value={[lista, setLista]}>{children}</Ctx.Provider>
  );
}

ParoquiasProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useParoquias = () => {
  return useContext(Ctx);
};
