import React from 'react';
import { IMaskInput } from 'react-imask';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import CampoTelefone from './Telefone';

export const CampoCpf = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='000.000.000-00'
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

CampoCpf.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
};

export default CampoTelefone;
