import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import MenuSuperior from './componentes/MenuSuperior/MenuSuperior';
import './global.css';
import { useAuth } from './hooks/Auth';
import Atendimentos from './paginas/Atendimentos/Lista.js';
import Clientes from './paginas/Clientes/Clientes';
import Diretores from './paginas/Diretores/Diretores';
import Inicial from './paginas/Inicial/Inicial';
import Paroquias from './paginas/Paroquias/Paroquias.js';
import Perguntas from './paginas/Perguntas/Perguntas';
import Roteiros from './paginas/Roteiros/Roteiros';
import Usuarios from './paginas/Usuarios/Usuarios';
import { retornarCookie } from './uteis/Funcoes';
import { paramCookieMaster } from './uteis/Parametros';

/**
 * Componente para página inicial.
 * @return {void} retorna a página de acordo com a autenticação.
 */
function PaginaRouter() {
  return (
    <Routes>
      <Route index element={<Inicial />} />
      <Route path='usuarios' element={<Usuarios />} />
      <Route path='perguntas' element={<Perguntas />} />
      <Route path='diretores' element={<Diretores />} />
      <Route path='clientes' element={<Clientes />} />
      <Route path='roteiros' element={<Roteiros />} />
      <Route path='paroquias' element={<Paroquias />} />
      <Route path='atendimentos' element={<Atendimentos />} />
    </Routes>
  );
}

/**
 * Componente para página inicial.
 * @return {void} retorna a página de acordo com a autenticação.
 */
export default function App() {
  const [, , , setMaster] = useAuth();

  useEffect(() => {
    if (Boolean(retornarCookie(paramCookieMaster))) setMaster(true);
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <MenuSuperior />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          background: '#F2F2F7',
        }}
      >
        <Toolbar />
        <Container className='fk-container' maxWidth='xl' sx={{ mb: 4 }}>
          <PaginaRouter />
        </Container>
      </Box>
    </Box>
  );
}
