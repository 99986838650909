/* eslint-disable no-unused-vars */
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  Stack,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CaixaSelecao from '../../../componentes/Campo/CaixaSelecao';
import MultiplaEscolha from '../../../componentes/Campo/MultiplaEscolha';
import Texto from '../../../componentes/Campo/Texto';
import { useApi } from '../../../hooks/Api';
import { usePerguntas } from '../../../hooks/listagem/Perguntas';

export const Ctx = createContext();

const url = 'perguntas/nova-ordem';
const titulo = 'Reordenar perguntas';
const botaoCadastrar = 'Salvar';
const botaoCancelar = 'Fechar';

/**
 * @param {object} props
 * @return {object}
 */
function ElementoPergunta(props) {
  // eslint-disable-next-line react/prop-types
  const { tipo } = props;

  switch (tipo) {
    case 1:
      return <MultiplaEscolha {...props} />;
    case 2:
      return <CaixaSelecao {...props} />;
    case 3:
      return <Texto {...props} />;
  }
}

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function ReordenarProvider({ children }) {
  const [aberto, setReordenarAberto] = useState(false);
  const [enviando, setEnviando] = useState(false);
  const [perguntas, setPerguntas] = usePerguntas();
  const [perguntasPrev, setPerguntasPrev] = useState(perguntas);
  const [novaOrdem, setNovaOrdem] = useState();
  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setReordenarAberto(false);
    setNovaOrdem();
    setPerguntasPrev(perguntas);
  }

  /**
   * @param {object} item
   * @param {number} index
   * @param {number} posicaoDesejada
   */
  function reordenarPergunta(item, index, posicaoDesejada) {
    const prevPrev = structuredClone(perguntasPrev);

    prevPrev.splice(index, 1);

    prevPrev.splice(posicaoDesejada, 0, item);

    setPerguntasPrev(prevPrev);

    const prevNovaOrdem = [];

    prevPrev.forEach((pItem, pIndex) => {
      prevNovaOrdem.push({ id: pItem.id, ordem: pIndex + 1 });
    });

    setNovaOrdem(prevNovaOrdem);
  }

  /**
   */
  function handleEnviar() {
    setEnviando(true);
    const data = { novaOrdem };

    api
      .post(url, data)
      .then((r) => {
        setPerguntas(r);

        toast.success('Ordem de perguntas alterada com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
        handleFechar();
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  useEffect(() => {
    if (perguntas) {
      setPerguntasPrev(perguntas);

      const prevNovaOrdem = [];

      perguntas.forEach((item) => {
        prevNovaOrdem.push({ id: item.id, ordem: item.ordem });
      });

      setNovaOrdem(prevNovaOrdem);
    }
  }, [perguntas]);

  return (
    <Ctx.Provider value={[setReordenarAberto]}>
      <Dialog
        open={aberto}
        onClose={handleFechar}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        maxWidth='md'
      >
        <DialogTitle id='alert-dialog-title'>{titulo}</DialogTitle>
        <DialogContent>
          <List>
            {perguntasPrev &&
              perguntasPrev.map((item, index) => (
                <ListItem
                  key={`item-map-pergunta-${index}`}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span className='qt-questionario-item'>
                    <ElementoPergunta {...item} />
                  </span>
                  <ListItemIcon>
                    <Stack direction={'column'}>
                      <Button
                        disabled={index === 0}
                        disableRipple
                        onClick={() => {
                          reordenarPergunta(item, index, index - 1);
                        }}
                      >
                        <ArrowDropUp
                          sx={{
                            border: '1px solid #c0c0c0',
                            borderTopLeftRadius: 3,
                            borderTopRightRadius: 3,
                          }}
                        />
                      </Button>
                      <Button
                        disabled={index === perguntasPrev.length - 1}
                        disableRipple
                        onClick={() => {
                          reordenarPergunta(item, index, index + 1);
                        }}
                      >
                        <ArrowDropDown
                          sx={{
                            border: '1px solid #c0c0c0',
                            borderBottomLeftRadius: 3,
                            borderBottomRightRadius: 3,
                          }}
                        />
                      </Button>
                    </Stack>
                  </ListItemIcon>
                </ListItem>
              ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFechar}>Cancelar</Button>
          <LoadingButton
            loading={enviando}
            variant='contained'
            disabled={perguntasPrev === perguntas}
            onClick={handleEnviar}
          >
            Salvar
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {children}
    </Ctx.Provider>
  );
}

ReordenarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useReordenar = () => {
  return useContext(Ctx);
};
