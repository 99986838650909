import {
  DialogActions,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useParoquias } from '../../../hooks/listagem/Paroquias';
import { base64ToObjectURL, filtrarPorNome } from '../../../uteis/Funcoes';

const titulo = 'Visualizar Cliente';
const botaoCancelarMensagem = 'Fechar';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function VisualizarClienteProvider({ children }) {
  const [alvo, setAlvo] = useState();
  const [paroquias] = useParoquias();

  /**
   */
  function handleFechar() {
    setAlvo();
  }

  return (
    <Ctx.Provider value={[setAlvo]}>
      {alvo && (
        <Dialog
          open={alvo != null}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle>{titulo}</DialogTitle>
          <DialogContent
            sx={{ overflow: 'visible' }}
            className='jpf-visualizar-form'
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  fullWidth
                  name='nome'
                  label='Nome'
                  autoComplete='off'
                  defaultValue={alvo.nome}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  fullWidth
                  name='endereco'
                  label='Endereço'
                  autoComplete='off'
                  multiline
                  rows={3}
                  defaultValue={alvo.endereco}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name='fone1'
                  label='Fone 1'
                  autoComplete='off'
                  defaultValue={alvo.fone1}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name='fone2'
                  label='Fone 2'
                  autoComplete='off'
                  defaultValue={alvo.fone2}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name='cpf'
                  label='CPF'
                  autoComplete='off'
                  defaultValue={alvo.cpf}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name='rg'
                  defaultValue={alvo.rg}
                  label='RG'
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name='nascimento'
                  label='Data de nascimento'
                  autoComplete='off'
                  defaultValue={alvo.nascimento}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name='pagamento'
                  label='Forma de pagamento'
                  autoComplete='off'
                  defaultValue={alvo.pagamento}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name='email'
                  label='E-mail'
                  autoComplete='off'
                  defaultValue={alvo.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name='emergencia'
                  label='Contato de Emergencia'
                  autoComplete='off'
                  defaultValue={alvo.emergencia}
                />
              </Grid>
              {paroquias && (
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <InputLabel>Paroquia *</InputLabel>
                    <Select
                      label='Paroquia *'
                      name='paroquia'
                      required
                      defaultValue={alvo.paroquia}
                    >
                      {structuredClone(paroquias)
                        .sort(filtrarPorNome)
                        .map(({ id, nome }, index) => (
                          <MenuItem
                            key={`item-map-paroquia-${index}`}
                            value={id}
                          >
                            {nome}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name='mae'
                  label='Nome da mãe'
                  autoComplete='off'
                  defaultValue={alvo.mae}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name='profissao'
                  label='Profissão'
                  autoComplete='off'
                  defaultValue={alvo.profissao}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  name='passaporte'
                  label='Nº Passaporte'
                  autoComplete='off'
                  defaultValue={alvo.passaporte}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  name='viagens'
                  label='Viagens realizadas'
                  autoComplete='off'
                  multiline
                  rows={3}
                  defaultValue={alvo.viagens}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Divider sx={{ opacity: 1 }} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Paper className='jpf-visualizar-form-clicavel'>
                  <List>
                    <ListItem
                      secondaryAction={
                        alvo.fotoIdentidade ? (
                          <Button
                            variant='outlined'
                            onClick={() => {
                              window.open(
                                base64ToObjectURL(alvo.fotoIdentidade)
                              );
                            }}
                          >
                            Visualizar
                          </Button>
                        ) : (
                          'Não informado'
                        )
                      }
                    >
                      <ListItemText primary='Identidade/CNH' />
                    </ListItem>
                    <Divider sx={{ opacity: 0.8 }} />
                    <ListItem
                      secondaryAction={
                        alvo.fotoEndereco ? (
                          <Button
                            variant='outlined'
                            onClick={() => {
                              window.open(base64ToObjectURL(alvo.fotoEndereco));
                            }}
                          >
                            Visualizar
                          </Button>
                        ) : (
                          'Não informado'
                        )
                      }
                    >
                      <ListItemText primary='Comprovante de endereço' />
                    </ListItem>
                    <Divider sx={{ opacity: 0.8 }} />
                    <ListItem
                      secondaryAction={
                        alvo.fotoPassaporte ? (
                          <Button
                            variant='outlined'
                            onClick={() => {
                              window.open(
                                base64ToObjectURL(alvo.fotoPassaporte)
                              );
                            }}
                          >
                            Visualizar
                          </Button>
                        ) : (
                          'Não informado'
                        )
                      }
                    >
                      <ListItemText primary='Passaporte' />
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type='button' variant='outlined' onClick={handleFechar}>
              {botaoCancelarMensagem}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

VisualizarClienteProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useVisualizarCliente = () => {
  return useContext(Ctx);
};
