import React from 'react';
import PropTypes from 'prop-types';
import CaixaSelecao from '../Campo/CaixaSelecao';
import MultiplaEscolha from '../Campo/MultiplaEscolha';
import Texto from '../Campo/Texto';

/**
 * @param {object} props
 * @return {object}
 */
export function ElementoPergunta(props) {
  const { tipo } = props;

  switch (tipo) {
    case 1:
      return <MultiplaEscolha {...props} />;
    case 2:
      return <CaixaSelecao {...props} />;
    case 3:
      return <Texto {...props} />;
  }
}

ElementoPergunta.propTypes = {
  tipo: PropTypes.object.isRequired,
};

export default ElementoPergunta;
