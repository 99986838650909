/* eslint-disable new-cap */
import {
  Backdrop,
  Button,
  CircularProgress,
  Paper,
  Stack,
  TableContainer,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFuncoes } from '../../hooks/FuncoesGerais';
import { useAtendimentos } from '../../hooks/listagem/Atendimentos';
import { useRoteiros } from '../../hooks/listagem/Roteiros';
import { converterData } from '../../uteis/Funcoes';
import { mobile } from '../../uteis/Parametros';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { useApi } from '../../hooks/Api';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const titulo = 'Roteiros';

/**
 * @return {object}
 */
function ColunasHead() {
  return (
    <TableRow>
      <TableCell align='center'>Imagem</TableCell>
      <TableCell align='center'>Título</TableCell>
      <TableCell align='center'>Data Partida</TableCell>
      <TableCell align='center'>Data Retorno</TableCell>
      <TableCell align='center'>Tipo</TableCell>
      <TableCell align='center'>Meta</TableCell>
      <TableCell align='center'>Confirmados</TableCell>
      <TableCell align='center'>Restante</TableCell>
      <TableCell align='right'></TableCell>
    </TableRow>
  );
}

/**
 * @return {object}
 */
function ListaSkeleton() {
  return Array(5)
    .fill()
    .map((item, index) => (
      <TableRow key={`skeleton-diretor-item-${index}`}>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'2ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='rectangle' width={'80px'} height={'80px'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'19ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'14ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'2ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'2ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'2ch'} />
        </TableCell>
        <TableCell align='center' sx={{ textAlign: '-webkit-center' }}>
          <Skeleton variant='text' width={'2ch'} />
        </TableCell>
      </TableRow>
    ));
}

/**
 * @param {array} listagem
 * @param {func} tentarNovamente
 * @return {object}
 */
function Lista({ listagem, tentarNovamente }) {
  const [atendimentos] = useAtendimentos();

  const [geralLoading, setGeralLoading] = useState(false);

  const ApiController = useApi();
  const api = new ApiController();

  /**
   * @param {number} id
   * @return {number}
   */
  function calcularRestante(id) {
    const atendimentosPorItem = atendimentos.filter(
      (item) => item.roteiro == id && item.viajou === 1
    );

    return atendimentosPorItem.length;
  }

  /**
   * @param {string} postId
   * @param {string} postTitle
   */
  function handleGenerate(postId, postTitle) {
    setGeralLoading(true);
    api
      .post('roteiros/relatorio', { postId })
      .then((r) => {
        const doc = new jsPDF();
        const title = 'Relatório de assentos - ' + postTitle;
        const padding = 10;
        const titleWidth = doc.getTextWidth(title);
        const center = doc.internal.pageSize.width / 2 - titleWidth / 2;
        doc.setTextColor('black');
        doc.text(title, center, padding);

        const data = Array.from({ length: 56 }, (v, i) => ({
          assento: i + 1,
          passageiro: 'Nenhum',
        }));

        r.relatorio.forEach((item) => {
          data[item.assento - 1] = {
            assento: item.assento,
            passageiro: item.passageiro,
          };
        });

        console.log(r.relatorio, data);

        doc.autoTable({
          head: [['Assento', 'Passageiro']],
          body: data.map((val, i) => [val.assento, val.passageiro]),
          headStyles: {
            fillColor: 'black',
            textColor: 'white',
          },
        });

        doc.save('relatorio-' + crypto.randomUUID() + '.pdf');
        setGeralLoading(false);
      })
      .catch((e) => {
        setGeralLoading(false);
      });
  }

  return (
    <React.Fragment>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={geralLoading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <Stack
        p={2}
        justifyContent={mobile ? 'unset' : 'space-between'}
        alignItems={mobile ? 'unset' : 'center'}
        direction={mobile ? 'column' : 'row'}
      >
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          {titulo}
        </Typography>
      </Stack>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <TableContainer
          sx={{ border: '1px solid #0000001a', borderRadius: '3px' }}
        >
          <Table size='small' aria-label='caption table'>
            <TableHead>
              <ColunasHead />
            </TableHead>
            <TableBody>
              {listagem && atendimentos ? (
                listagem.map(
                  (
                    {
                      post_id: postId,
                      post_title: postTitle,
                      post_cover: postCover,
                      post_data_roteiro: postDataRoteiro,
                      post_data_retorno: postDataRetorno,
                      post_tipo: postTipo,
                      post_meta: postMeta,
                    },
                    index
                  ) => (
                    <TableRow
                      key={`${titulo.toLocaleLowerCase()}-item-map-${index}`}
                    >
                      <TableCell align='center'>
                        <img
                          src={`https://operadorajpf.com.br/uploads/${postCover}`}
                          height='80'
                          width='80'
                        />
                      </TableCell>
                      <TableCell align='center'>{postTitle}</TableCell>
                      <TableCell align='center'>
                        {converterData(postDataRoteiro)}
                      </TableCell>
                      <TableCell align='center'>
                        {converterData(postDataRetorno)}
                      </TableCell>
                      <TableCell align='center'>{postTipo}</TableCell>
                      <TableCell align='center'>
                        {postMeta ? postMeta : 'Não informado'}
                      </TableCell>
                      <TableCell align='center'>
                        {postMeta ? calcularRestante(postId) : 0}
                      </TableCell>
                      <TableCell align='center'>
                        {postMeta ? postMeta - calcularRestante(postId) : 0}
                      </TableCell>
                      <TableCell align='right'>
                        <Button
                          variant='contained'
                          onClick={() => {
                            handleGenerate(postId, postTitle);
                          }}
                          startIcon={<PictureAsPdfIcon />}
                        >
                          Relatório
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                )
              ) : listagem === null && atendimentos == null ? (
                <TableRow>
                  <TableCell
                    className='text-center'
                    colSpan={5}
                    sx={{ height: '247.5px' }}
                  >
                    <Typography gutterBottom>
                      Houve um erro ao carregar a listagem!
                    </Typography>
                    <Button onClick={tentarNovamente}>Tentar novamente</Button>
                  </TableCell>
                </TableRow>
              ) : (
                <ListaSkeleton />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </React.Fragment>
  );
}

Lista.propTypes = {
  listagem: PropTypes.array,
  tentarNovamente: PropTypes.func.isRequired,
};

/**
 * @return {void} lista de clientes cadastrados.
 */
export default function Roteiros() {
  const [roteiros] = useRoteiros();
  const [, , , , , roteirosFetch, , atendimentosFetch] = useFuncoes();

  /**
   */
  function geralFetch() {
    roteirosFetch();
    atendimentosFetch();
  }

  useEffect(geralFetch, []);

  return (
    <Grid item xs={12} md={8} lg={9}>
      <Grid item xs={12}>
        <Lista listagem={roteiros} tentarNovamente={geralFetch} />
      </Grid>
    </Grid>
  );
}
