// Corrige a função structureClone para navegadores que não possuem suporte
if (typeof structuredClone !== 'function') {
  window.structuredClone = function (obj) {
    return JSON.parse(JSON.stringify(obj));
  };
}

// Funções gerais

/**
 * @param {string} cookie cookie a ser retornado
 * @return {string} Cookie
 *  */
export function retornarCookie(cookie) {
  try {
    const cookieBrowser = document.cookie.split(cookie + '=')[1].split(';')[0];
    return cookieBrowser;
  } catch (e) {
    return null;
  }
}

/**
 * @param {string} cookie cookie a ser removido
 * @return {void}
 *  */
export function removerCookie(cookie) {
  document.cookie = cookie + '=' + ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
}

/**
 * @param {string} name Cookie
 * @return {string} cookie
 */
export function retornarCookieCodificado(name) {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');
  if (parts.length == 2) {
    const vlu = parts.pop().split(';').shift();
    const decodeVlu = decodeURIComponent(vlu);
    const replaceVlu = decodeVlu.replace(/[+]/g, ' ');
    return JSON.parse(replaceVlu);
  } else return undefined;
}

/**
 * @param {number} numero
 * @return {string}
 */
function adicionarZero(numero) {
  // Adiciona um zero à esquerda se o número for menor que 10
  return numero < 10 ? '0' + numero : numero;
}

/**
 * @param {string} dataString
 * @return {string}
 */
export function formatarDataHora(dataString) {
  // Cria um objeto Date com a string fornecida
  const data = new Date(dataString);

  // Extrai os componentes da data
  const dia = adicionarZero(data.getDate());
  const mes = adicionarZero(data.getMonth() + 1);
  const ano = data.getFullYear();
  const hora = adicionarZero(data.getHours());
  const minuto = adicionarZero(data.getMinutes());
  const segundo = adicionarZero(data.getSeconds());

  // Retorna a data formatada
  return (
    dia + '/' + mes + '/' + ano + ' ' + hora + ':' + minuto + ':' + segundo
  );
}

/**
 * @param {object} item
 * @param {object} prev
 * @return {object}
 */
export function inserirItemNoState(item, prev) {
  const prevClone = structuredClone(prev);

  const novo = [...prevClone, item];

  return novo;
}

/**
 * @param {object} item
 * @param {object} prev
 * @return {object}
 */
export function alterarItemNoState(item, prev) {
  const prevClone = structuredClone(prev);

  const itemEncontrado = prevClone.findIndex((pItem) => pItem.id == item.id);

  if (itemEncontrado == -1) {
    return prev;
  }

  prevClone[itemEncontrado] = item;

  return prevClone;
}

/**
 * @param {object} id
 * @param {object} prev
 * @return {object}
 */
export function removerItemDoState(id, prev) {
  const prevClone = structuredClone(prev);

  const itemEncontrado = prevClone.findIndex((item) => item.id == id);

  if (itemEncontrado == -1) {
    return prev;
  }

  prevClone.splice(itemEncontrado, 1);

  return prevClone;
}

/**
 * @param {number} number
 * @return {string}
 */
export function formatarValorParaReais(number) {
  return number
    .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    .replace('R$', '')
    .trim();
}

/**
 * @param {number} a
 * @param {number} b
 * @return {number}
 */
export function filtrarPorNome(a, b) {
  const nomeA = a.nome.toUpperCase();
  const nomeB = b.nome.toUpperCase();

  if (nomeA < nomeB) {
    return -1;
  }
  if (nomeA > nomeB) {
    return 1;
  }
  return 0;
}

/**
 * @param {string} data
 * @return {string}
 */
export function converterData(data) {
  const partesData = data.split('-');
  return `${partesData[2]}/${partesData[1]}/${partesData[0]}`;
}

/**
 * @param {string} base64String
 * @return {string}
 */
export function base64ToObjectURL(base64String) {
  // Remover a parte do prefixo do tipo de dados (ex: "data:image/png;base64,")
  const base64Data = base64String.split(',')[1];

  // Converter base64 para bytes binários (ArrayBuffer)
  const binaryString = atob(base64Data);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Criar um Blob a partir dos bytes binários
  const blob = new Blob([bytes], { type: 'image/png' }); // Defina o tipo de acordo com a imagem

  // Criar um Object URL
  const objectURL = URL.createObjectURL(blob);

  return objectURL;
}
