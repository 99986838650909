import { LoadingButton } from '@mui/lab';
import {
  Box,
  DialogActions,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useApi } from '../../../hooks/Api';
import { useClientes } from '../../../hooks/listagem/Clientes';
import { alterarItemNoState, filtrarPorNome } from '../../../uteis/Funcoes';
import CampoTelefone from '../../../componentes/Campo/Telefone';
import { CampoCpf } from '../../../componentes/Campo/Cpf';
import CampoNascimento from '../../../componentes/Campo/Nascimento';
import { useParoquias } from '../../../hooks/listagem/Paroquias';
import { useNovaParoquia } from '../../Paroquias/Acoes/Novo';
import { listaBotaoCpf } from '../../../uteis/Parametros';
import UploadAnexo from '../../../componentes/Campo/UploadAnexo';

const url = 'clientes/alterar';
const titulo = 'Alterar Cliente';
const botaoCadastrarMensagem = 'Salvar';
const botaoCancelarMensagem = 'Fechar';

export const Ctx = createContext();

/**
 * Hooks manager
 * @param {any} children
 * @return {children} Hooks.
 */
export function AlterarClienteProvider({ children }) {
  const [alvo, setAlvo] = useState();
  const [enviando, setEnviando] = useState(false);
  const [, setClientes] = useClientes();
  const [paroquias] = useParoquias();

  const [estadoCpf, setEstadoCpf] = useState(0);

  const [cpf, setCpf] = useState();

  const [setNovaParoquia] = useNovaParoquia();

  const ApiController = useApi();
  const api = new ApiController();

  /**
   */
  function handleFechar() {
    setAlvo();
    setEstadoCpf(0);
    setCpf();
  }

  /**
   */
  function handleNovaParoquia() {
    setNovaParoquia(true);
  }

  /**
   * @param {object} e
   */
  function handleCpf(e) {
    setEstadoCpf(0);
    setCpf(e.target.value);
  }

  /**
   */
  function handleVerificarCpf() {
    setEstadoCpf(1);

    api
      .post('clientes/verificar-cpf', { cpf, id: alvo.id })
      .then((r) => {
        setEstadoCpf(r.status);
      })
      .catch((e) => {
        console.error(e);
        setEstadoCpf(0);
      });
  }

  /**
   * @param {object} event eventos
   */
  function handleEnviar(event) {
    event.preventDefault();
    setEnviando(true);
    const data = new FormData(event.currentTarget);

    if (alvo) {
      data.append('id', alvo.id);
    }

    api
      .post(url, data)
      .then((r) => {
        setClientes((prev) => alterarItemNoState(r, prev));

        toast.success('Cliente alterado com sucesso', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'colored',
          transition: Bounce,
        });

        setEnviando(false);
      })
      .catch((e) => {
        console.error(e);
        setEnviando(false);
      });
  }

  return (
    <Ctx.Provider value={[setAlvo]}>
      {alvo && (
        <Dialog
          open={alvo != null}
          onClose={handleFechar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle>{titulo}</DialogTitle>
          <DialogContent sx={{ overflow: 'visible' }}>
            <Box component='form' autoComplete='off' onSubmit={handleEnviar}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    fullWidth
                    name='nome'
                    label='Nome'
                    autoComplete='off'
                    defaultValue={alvo.nome}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: '100%' }} variant='outlined'>
                    <InputLabel htmlFor='cpf'>CPF</InputLabel>
                    <OutlinedInput
                      name='cpf'
                      inputComponent={CampoCpf}
                      label='CPF'
                      placeholder='XXX.XXX.XXX-XX'
                      onChange={handleCpf}
                      disabled={estadoCpf === 1}
                      defaultValue={alvo.cpf}
                      endAdornment={
                        <LoadingButton
                          onClick={handleVerificarCpf}
                          loading={estadoCpf === 1}
                          disabled={cpf?.length !== 14}
                          size='small'
                        >
                          {listaBotaoCpf[estadoCpf]}
                        </LoadingButton>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name='rg'
                    defaultValue={alvo.rg}
                    label='RG'
                    autoComplete='off'
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    fullWidth
                    name='endereco'
                    label='Endereço'
                    autoComplete='off'
                    multiline
                    rows={3}
                    defaultValue={alvo.endereco}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: '100%' }} variant='outlined'>
                    <InputLabel htmlFor='fone1'>Fone 1 *</InputLabel>
                    <OutlinedInput
                      name='fone1'
                      inputComponent={CampoTelefone}
                      label='Fone 1 *'
                      placeholder='(XX) 9XXXX-XXXX'
                      required
                      defaultValue={alvo.fone1}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: '100%' }} variant='outlined'>
                    <InputLabel htmlFor='fone2'>Fone 2</InputLabel>
                    <OutlinedInput
                      name='fone2'
                      inputComponent={CampoTelefone}
                      label='Fone 2'
                      placeholder='(XX) 9XXXX-XXXX'
                      defaultValue={alvo.fone2}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: '100%' }} variant='outlined'>
                    <InputLabel htmlFor='nascimento'>
                      Data de nascimento
                    </InputLabel>
                    <OutlinedInput
                      name='nascimento'
                      inputComponent={CampoNascimento}
                      label='Data de nascimento'
                      placeholder='XX/XX/XXXX'
                      defaultValue={alvo.nascimento}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name='pagamento'
                    label='Forma de pagamento'
                    autoComplete='off'
                    defaultValue={alvo.pagamento}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name='email'
                    label='E-mail'
                    autoComplete='off'
                    defaultValue={alvo.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name='emergencia'
                    label='Contato de Emergencia'
                    autoComplete='off'
                    defaultValue={alvo.emergencia}
                  />
                </Grid>
                {paroquias && (
                  <>
                    <Grid item xs={12} sm={8}>
                      <FormControl fullWidth>
                        <InputLabel>Paroquia</InputLabel>
                        <Select
                          label='Paroquia'
                          name='paroquia'
                          defaultValue={alvo.paroquia}
                        >
                          {structuredClone(paroquias)
                            .sort(filtrarPorNome)
                            .map(({ id, nome }, index) => (
                              <MenuItem
                                key={`item-map-paroquia-${index}`}
                                value={id}
                              >
                                {nome}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      alignItems={'center'}
                      justifyContent={'center'}
                      display={'flex'}
                    >
                      <Button variant='contained' onClick={handleNovaParoquia}>
                        Nova Paróquia
                      </Button>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name='mae'
                    label='Nome da mãe'
                    autoComplete='off'
                    defaultValue={alvo.mae}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name='profissao'
                    label='Profissão'
                    autoComplete='off'
                    defaultValue={alvo.profissao}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    name='passaporte'
                    label='Nº Passaporte'
                    autoComplete='off'
                    defaultValue={alvo.passaporte}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    name='viagens'
                    label='Viagens realizadas'
                    autoComplete='off'
                    multiline
                    rows={3}
                    defaultValue={alvo.viagens}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Divider sx={{ opacity: 1 }} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Paper>
                    <List>
                      <ListItem
                        secondaryAction={
                          <UploadAnexo
                            name='foto_identidade'
                            defaultValue={alvo.fotoIdentidade}
                          />
                        }
                      >
                        <ListItemText primary='Identidade/CNH' />
                      </ListItem>
                      <Divider sx={{ opacity: 0.8 }} />
                      <ListItem
                        secondaryAction={
                          <UploadAnexo
                            name='foto_endereco'
                            defaultValue={alvo.fotoEndereco}
                          />
                        }
                      >
                        <ListItemText primary='Comprovante de endereço' />
                      </ListItem>
                      <Divider sx={{ opacity: 0.8 }} />
                      <ListItem
                        secondaryAction={
                          <UploadAnexo
                            name='foto_passaporte'
                            defaultValue={alvo.fotoPassaporte}
                          />
                        }
                      >
                        <ListItemText primary='Passaporte' />
                      </ListItem>
                    </List>
                  </Paper>
                </Grid>
              </Grid>
              <DialogActions sx={{ p: 0, mt: 3 }}>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={enviando}
                >
                  {botaoCadastrarMensagem}
                </LoadingButton>
                <Button
                  type='button'
                  variant='outlined'
                  onClick={handleFechar}
                  disabled={enviando}
                >
                  {botaoCancelarMensagem}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {children}
    </Ctx.Provider>
  );
}

AlterarClienteProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAlterarCliente = () => {
  return useContext(Ctx);
};
