import React from 'react';
import { IMaskInput } from 'react-imask';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';

export const CampoNascimento = React.forwardRef(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask='00/00/0000'
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

CampoNascimento.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
};

export default CampoNascimento;
